import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import config from '../../config';
import { getUnhandled, setData } from '../../actions/chatPayAction';

function Initial(props) {
  const dispatch = useDispatch();
  const selectOrg = useSelector(store => store.organization.selectOrg);

  // 取得訊即購所有待確認訂單數
  useEffect(() => {
    if (selectOrg?.objectId && selectOrg?.features?.shop8?.enable) {
      dispatch(getUnhandled(selectOrg?.objectId));
    } else {
      dispatch(setData({ unhandledCount: 0 }));
    }
  }, [selectOrg]);

  useEffect(() => {
    if (Notification && Notification.requestPermission) {
      const requestPermission = Notification.requestPermission();
      if (requestPermission) {
        requestPermission.then(() => {
          // console.log('requestPermission', result);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (Sentry && config?.SENTRY_DSN) {
      Sentry.init({
        dsn: config.SENTRY_DSN,

        // This enables automatic instrumentation (highly recommended), but is not
        // necessary for purely manual usage
        integrations: [new BrowserTracing()],

        // To set a uniform sample rate
        tracesSampleRate: 1.0,
      });
    }
  }, [Sentry]);

  return <>{props.children}</>;
}

export default Initial;
