import { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import hexRgba from 'hex-rgba';
import { useTranslation } from 'react-i18next';
import { toggleNavBar } from '../../actions';
import { color } from '../../themes';
import imgSrc from '../../assets/iconSrc';
import ImageDisplay from './ImageDisplay';
import OrganizationList from './OrganizationList';
import SystemTaskListItem from '../system-task/SystemTaskListItem';
import NavigationBarFeatureListItem from './NavigationBarFeatureListItem';
import NotificationItem from '../notification-center/NotificationItem';
import IconButton from './IconButton';
import packageJson from '../../../package.json';
import Announcement from './Announcement';
import { resetSuperMarket } from '../../actions/superMarketAction';
import config from '../../config';

const drawerWidth = 256;
const appBarHeight = 64;

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100vw',
      display: 'flex',
    },
    appBar: props => ({
      position: 'fixed',
      top: props.decreaseHeight,
      left: 0,
      // backgroundImage: 'linear-gradient(87deg, #2ca6e0, #88c029)',
      backgroundColor: color.$7,
      boxShadow:
        '0 5px 18px 0 rgba(0, 0, 0, 0.08), 0 0 11px -2px rgba(0, 0, 0, 0.06)',
      height: appBarHeight,
      color: color.$3,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      zIndex: 3001,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    'appBar--legacy': {
      padding: '0 0 0 36px',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: props => ({
      top: props.decreaseHeight,
      width: drawerWidth,
      overflow: 'hidden',
      backgroundColor: color.$6,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    drawerClose: props => ({
      top: props.decreaseHeight,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflow: 'hidden',
      width: 80,
      backgroundColor: color.$6,
    }),
    drawerPaperAnchorDockedLeft: {
      borderRight: 'none',
    },
    content: {
      flexGrow: 1,
      margin: `${appBarHeight + 36}px 44px 96px 44px`,
      height: `calc(100vh - ${appBarHeight + 36}px)`,
    },
    tabContent: {
      flexGrow: 1,
      margin: `${appBarHeight}px 24px 96px 24px`,
      minHeight: `calc(100vh - ${appBarHeight + 96}px)`,
    },
    normalContent: props => ({
      position: 'relative',
      marginTop: 64,
      width: 'calc(100vw - 256px)',
      height: `calc(100vh - ${appBarHeight}px - ${props.decreaseHeight}px)`,
    }),
    'normalContent--sidebar-hidden': {
      width: 'calc(100vw - 80px) !important',
    },
    'normalContent--no-sidebar': {
      width: '100%',
    },
    navList: props => ({
      padding: '0',
      overflow: 'hidden auto',
      height: `calc(100vh - 154px - 234px - ${props.decreaseHeight}px)`,
    }),
    navListBottom: props => ({
      position: 'absolute',
      bottom: props.decreaseHeight,
      padding: '4px 0px 16px 0px',
      backgroundColor: color.$6,
      width: '100%',
    }),
    'navListBottom--open': {
      color: color.$7,
      marginLeft: 4,
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    avatar: {
      width: 32,
      height: 32,
      margin: '0px 12px 0px 10px',
    },
    listitem: {
      padding: '0 14px',
      '&:hover': {
        backgroundColor: hexRgba(color.$3, 10),
      },
    },
    selected: {
      backgroundColor: `${hexRgba(color.$3, 10)} !important`,
    },
    versionText: {
      fontSize: 11,
      color: color.$11,
      textAlign: 'center',
      width: 'max-content',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      margin: '4px auto 0',
    },
    textStyleOpen: {
      color: color.$7,
      marginLeft: '16px',
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    textStyleClose: { display: 'none' },
    orgImg: {
      borderRadius: '28px',
      margin: '0 24px 0 auto',
      width: '36px',
      height: '36px',
    },
    hidden: {
      display: 'none',
    },
  }),
  {
    name: 'NavigationBar',
  },
);

const NavigationBar = () => {
  const decreaseHeight = useSelector(
    store => store.organization.decreaseHeight,
  );
  const classes = useStyles({
    decreaseHeight,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { i18n, t } = useTranslation();
  const advanceSearchOpen = useSelector(store => store.navBarOpen);
  const organization = useSelector(store => store.organization);
  const { unhandledCount } = useSelector(store => store.chatPay);
  const auth = useSelector(store => store.auth);
  const [open, setOpen] = useState(advanceSearchOpen);
  const { integrations, selectOrg, orgData } = organization;
  const page = pathname.split('/')[1];

  const { orgOwnData } = auth || {};
  const { feature } = auth || {};
  const { authorityName, orgRole } = orgOwnData || {};
  const ACCOUNT_SETTING_PAGE = '/account-setting';

  const isEnabledLineRoleSetting = selectOrg?.features?.lineRoleSetting?.enable;
  const isEnabledOfflineSetting = selectOrg?.features?.offlineSetting?.enable;
  // const isEnabledChatPay = selectOrg?.features?.shop8?.enable;

  let tabIndex = 0;
  useEffect(() => {
    if (selectOrg) {
      setOpen(advanceSearchOpen);
    }
  }, [selectOrg]);

  const openHelpNo8 = () => {
    // window.open(t('openHelpNo8'));
  };

  const handleSidebarExpand = () => {
    dispatch(toggleNavBar(!open));
    setOpen(!open);
  };

  const directTo = to => {
    if (to === 'superMarket') {
      dispatch(resetSuperMarket());
    }
    navigate(`/${to}/${organization.selectOrg.objectId}`);
  };

  const isAccountPage = () => {
    return pathname.indexOf(ACCOUNT_SETTING_PAGE) !== -1;
  };

  const isShowChatPayBadge = !!unhandledCount;

  const isShowOrganiztionSettingbadge = integrations.reduce(
    (pre, integration) => {
      const { alert } = integration;
      return pre || alert;
    },
    false,
  );

  const isNotLinkIntegration = !(integrations || []).length;

  const isNormalMember = authorityName !== 'admin';
  let mainfeatures = isNotLinkIntegration
    ? ['organization-setting']
    : isNormalMember
    ? ['overview', 'message-center', 'chatPay', 'organization-setting']
    : [
        'overview',
        // 'super-link',
        'message-center',
        'message-bot',
        'marketing-automation',
        'customer-management',
        'broadcast',
        'superMarket',
        // 'webview-editor',
        'chatPay',
        'organization-setting',
      ];

  if (
    isNormalMember &&
    (isEnabledLineRoleSetting || isEnabledOfflineSetting) &&
    !isNotLinkIntegration
  ) {
    mainfeatures.splice(2, 0, 'superMarket');
  }

  // if (isEnabledChatPay) {
  // mainfeatures.splice(mainfeatures.length - 1, 0, 'chatPay');
  // }

  if (orgRole) {
    mainfeatures = [];
    if (feature?.overview?.enabled) {
      if (
        feature?.overview?.dataAnalysis?.enabled ||
        feature?.overview?.tagAnalysis?.enabled ||
        feature?.overview?.template?.enabled
      ) {
        mainfeatures.push('overview');
      }
    }
    // if (feature?.superLink?.enabled) {
    //   mainfeatures.push('super-link');
    // }
    if (feature?.msgCenter?.enabled) {
      mainfeatures.push('message-center');
    }
    if (feature?.messageBot?.enabled) {
      mainfeatures.push('message-bot');
    }
    if (feature?.marketingAutomation?.enabled) {
      mainfeatures.push('marketing-automation');
    }
    if (feature?.customerMgmt?.enabled) {
      if (
        feature?.customerMgmt?.allCustomers?.enabled ||
        feature?.customerMgmt?.customerGroups?.enabled ||
        feature?.customerMgmt?.tagManagement?.enabled ||
        feature?.customerMgmt?.messageKeyword?.enabled
      ) {
        mainfeatures.push('customer-management');
      }
    }
    if (feature?.broadcast?.enabled) {
      mainfeatures.push('broadcast');
    }
    if (feature?.superMarketSetting?.enabled) {
      mainfeatures.push('superMarket');
    }

    // if (feature?.webviewEditor?.enabled) {
    //   mainfeatures.push('webview-editor');
    // }
    if (feature?.orgSetting?.enabled) {
      if (
        feature?.orgSetting?.lineApp?.enabled ||
        feature?.orgSetting?.basicInfo?.enabled ||
        feature?.orgSetting?.memberManagement?.enabled ||
        feature?.orgSetting?.richMenuSetting?.enabled ||
        feature?.orgSetting?.redirectOptions?.enabled ||
        feature?.orgSetting?.orgAnnouenctments?.enabled
      ) {
        mainfeatures.push('organization-setting');
      }
    }
  }

  tabIndex = mainfeatures.length - 1;

  const getPageName = pageName => {
    return t(`organization.${pageName}`);
  };

  const getSuperMarketName = childPageName => {
    let returnPageName = childPageName;
    switch (childPageName) {
      case 'roulette':
        returnPageName = 'game_module_roulette';
        break;
      case 'scratch':
        returnPageName = 'game_module_scratch';
        break;
      case 'slotMachine':
        returnPageName = 'game_module_slot';
        break;
      case 'edit-campaign':
      case 'line-campaign':
        returnPageName = 'growth_tool';
        break;
      case 'coupon':
      case 'coupon-edit':
        returnPageName = 'coupon_module';
        break;
      default:
        break;
    }
    return returnPageName;
  };

  useEffect(() => {
    let childPage = pathname.split('/')[3];

    let menuName = '';

    switch (page) {
      case 'overview':
        menuName = t(`overview:tabs.${childPage}`);
        break;
      case 'message-bot':
        childPage = childPage === 'bot-theme-create' ? 'bot-theme' : childPage;
        // childPage = 'aiBot';
        // menuName = t(`organization-tabs.message-bot.${childPage}`);
        break;
      case 'customer-management':
        menuName = t(`customer-management.${childPage}-tab-title`);
        break;
      case 'superMarket':
        childPage = getSuperMarketName(childPage);
        if (childPage) {
          menuName = t(`superMarket:${childPage}.title`);
        } else {
          menuName = t(`superMarket:title`);
        }
        break;
      case 'organization-setting':
        childPage =
          childPage === 'permisson-setting' ? 'member-management' : childPage;
        menuName = t(`organization-setting.${childPage}.title`);
        break;
      case 'account-setting':
        const accountPage = pathname.split('/')[2];
        menuName = t(`account-setting.${accountPage}.title`);
        break;
      default:
        menuName = getPageName(page);
    }

    document.title = `${config.TITLE}${menuName}`;
  }, [pathname, i18n.language]);

  return (
    <>
      <Announcement />
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classnames(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <ImageDisplay
            alt="Super8"
            style={{
              objectFit: 'contain',
              cursor: 'pointer',
              margin: '0px 36px 0px 24px',
            }}
            onClick={handleSidebarExpand}
            src={imgSrc.Menu}
            width={24}
            height={24}
          />
          <Typography
            variant="h3"
            style={{
              fontSize: 17,
              color: color.$3,
              paddingLeft: 0,
            }}
          >
            {page ? getPageName(page) : ''}
          </Typography>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classnames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: classnames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
            paperAnchorDockedLeft: classes.drawerPaperAnchorDockedLeft,
          }}
          open={open}
        >
          <div
            style={{
              padding: '24px 24px 8px 20px',
              backgroundColor: color.$6,
            }}
          >
            <ImageDisplay
              alt="Super8"
              style={{
                width: '216px',
                height: '30px',
                objectFit: 'contain',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(`/`);
              }}
              src={imgSrc.Logo}
              width={82}
              height={32}
            />
            <img
              alt="Super8"
              style={{
                objectFit: 'contain',
                position: 'absolute',
                right: 0,
                top: 0,
              }}
              src={imgSrc.Ripple}
              width={86}
              height={64}
            />
          </div>
          <OrganizationList
            textStyle={open ? classes.textStyleOpen : classes.textStyleClose}
          />
          {orgData && selectOrg && (
            <List className={classes.navList}>
              {mainfeatures.map((key, index) => {
                return (
                  <NavigationBarFeatureListItem
                    open={open}
                    pageName={key}
                    tabIndex={index}
                    key={`nav-item-${key}`}
                    iconName={key}
                    selectOrg={selectOrg}
                    onClick={() => directTo(key)}
                    badgeShow={
                      (key === 'chatPay' && isShowChatPayBadge) ||
                      (key === 'organization-setting' &&
                        isShowOrganiztionSettingbadge)
                    }
                  />
                );
              })}
            </List>
          )}
          <List className={`${classes.navListBottom}`}>
            <ListItem
              button
              onClick={openHelpNo8}
              tabIndex={tabIndex + 1}
              className={classes.listitem}
            >
              <IconButton color="primary" component="span">
                <ImageDisplay src={imgSrc.helpCenter} width={24} height={24} />
              </IconButton>
              <Typography
                variant="subtitle1"
                className={
                  open ? classes['navListBottom--open'] : classes.textStyleClose
                }
              >
                {t('navigation.helpAndAnswer')}
              </Typography>
            </ListItem>
            <NotificationItem open={open} tabIndex={tabIndex + 2} />
            {orgData && selectOrg && (
              <SystemTaskListItem open={open} tabIndex={tabIndex + 3} />
            )}
            <Link to={ACCOUNT_SETTING_PAGE}>
              <ListItem
                className={classnames([
                  classes.listitem,
                  {
                    [classes.selected]: isAccountPage(),
                  },
                ])}
                tabIndex={tabIndex + 4}
                style={{ padding: '8px 14px' }}
              >
                <Avatar
                  src={auth.userData?.picture || imgSrc.defaultAvatar}
                  className={classes.avatar}
                />
                <Typography
                  variant="subtitle1"
                  className={
                    open ? classes.textStyleOpen : classes.textStyleClose
                  }
                  style={{ marginLeft: '0px' }}
                >
                  {auth.userData?.firstName}
                </Typography>
              </ListItem>
            </Link>
            <Typography className={classes.versionText}>
              {open ? (
                <>
                  {`${t('navigation.version', {
                    version: packageJson.version,
                  })}`}
                </>
              ) : (
                t('navigation.vip-short', {
                  version: packageJson.version,
                })
              )}
            </Typography>
          </List>
        </Drawer>
        <main
          className={classnames(classes.normalContent, {
            [classes['normalContent--sidebar-hidden']]: !open,
          })}
        >
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default NavigationBar;
