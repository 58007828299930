import {
  setInviteCodeByUrl,
  fetchUser,
  normalSignup,
  normalLogin,
  socialLogin,
  setPasswordLevel,
  userLogout,
  sendPasswordEmail,
  closePasswordSendTag,
  getPermission,
} from './userAction';
import { addTag, removeTag } from './tagAction';
import {
  uploadToBucket,
  listFiles,
  deleteFile,
  emptyBucket,
} from './fileAction';
import { fetchOrganization, selectOrganization } from './organizationAction';
import {
  findMessage,
  findCustomer,
  getCustomerLazy,
  getMessagelazy,
  setCustomerList,
  setSelectConvCustomer,
  updateCustomer,
  assignCustomer,
  getCustomerByCustomerIds,
  getCustomerFromId,
  getCustomerAccounting,
  customerAccountingList,
  receiveMessage,
  addMessageOfConversation,
  queryMessagesOfConversation,
  queryFirstMsgByDate,
  removeMessageOfHistory,
  keepMessage,
  unKeepMessage,
  getKeptMessages,
  getLastRead,
} from './customerAction';
import { getPreference, toggleConversationMute } from './preferenceAction';
import {
  getTemplateData,
  getTemplates,
  removeTemplate,
  publishTemplate,
} from './templateAction';
import crawl from './metaCrawlAction';
import {
  updateBroadcast,
  getBroadcastList,
  resetBroadcast,
  setBroadcastCreateCondition,
  setBroadcastCreateTimingType,
  setBroadcastCreateTiming,
  setBroadcastCreateMessage,
  setBroadcastCreateName,
  setBroadcastCustomerNum,
  sendBroadcast,
} from './broadcastAction';

import {
  getCampaigns,
  getCampaignByID,
  getCampaignAnalyze,
  getSharerData,
  exportShareRank,
  getCampaignChart,
  createCampaign,
  resetCampaignSelected,
  emptyCreateResult,
  updateCampaign,
  toggleCampaign,
  deleteCampaign,
  getChannelInfo,
  toggleSharerFan,
} from './growthToolAction';

import {
  getSuper8apiPermissions,
  getSuper8apiKey,
  getSuper8apiKeyV2,
  setSuper8apiKeyName,
  deleteSuper8apiKey,
} from './super8apiAction';

import actionType from './type';
import config from '../config';

const toggleNotifyPanel = data => ({
  type: actionType.TOGGLE_NOTIFY,
  payload: data,
});

const toggleFilterPanel = data => ({
  type: actionType.TOGGLE_FILTER_PANEL,
  payload: data,
});

const messageInfoSwitch = data => ({
  type: actionType.SWITCH_INFO_PANEL,
  payload: data,
});

const toggleNavBar = isOpen => {
  localStorage.setItem(config.IS_SIDEBAR_EXPAND_NAME, isOpen);
  return {
    type: actionType.TOGGLE_NAVBAR,
    payload: isOpen,
  };
};

export {
  setInviteCodeByUrl,
  toggleNavBar,
  toggleNotifyPanel,
  toggleFilterPanel,
  messageInfoSwitch,
  uploadToBucket,
  listFiles,
  deleteFile,
  fetchUser,
  normalSignup,
  normalLogin,
  socialLogin,
  userLogout,
  setPasswordLevel,
  sendPasswordEmail,
  closePasswordSendTag,
  fetchOrganization,
  selectOrganization,
  findCustomer,
  getCustomerLazy,
  getMessagelazy,
  setCustomerList,
  setSelectConvCustomer,
  updateCustomer,
  assignCustomer,
  findMessage,
  getCustomerByCustomerIds,
  getCustomerFromId,
  getCustomerAccounting,
  customerAccountingList,
  receiveMessage,
  addMessageOfConversation,
  queryMessagesOfConversation,
  queryFirstMsgByDate,
  removeMessageOfHistory,
  addTag,
  removeTag,
  keepMessage,
  unKeepMessage,
  getKeptMessages,
  getPreference,
  toggleConversationMute,
  getLastRead,
  getPermission,
  getTemplateData,
  getTemplates,
  removeTemplate,
  publishTemplate,
  crawl,
  updateBroadcast,
  getBroadcastList,
  resetBroadcast,
  setBroadcastCreateCondition,
  setBroadcastCreateTimingType,
  setBroadcastCreateTiming,
  setBroadcastCreateName,
  setBroadcastCreateMessage,
  setBroadcastCustomerNum,
  sendBroadcast,
  getCampaigns,
  getCampaignByID,
  getSharerData,
  exportShareRank,
  getCampaignAnalyze,
  getCampaignChart,
  createCampaign,
  updateCampaign,
  toggleCampaign,
  deleteCampaign,
  getSuper8apiPermissions,
  getSuper8apiKey,
  getSuper8apiKeyV2,
  setSuper8apiKeyName,
  deleteSuper8apiKey,
  emptyBucket,
  emptyCreateResult,
  getChannelInfo,
  resetCampaignSelected,
  toggleSharerFan,
};
