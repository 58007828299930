import uniqBy from 'lodash/uniqBy';

import actionType from '../actions/type';

const initialState = {
  messageHistory: [],
  keptMessage: [],
  delMessage: {},
  isConditionSearch: false,
  isCustomerAccountingListLoading: true,
  totalCustomerNumber: 0,
  customerAccountingList: [],
  errorMessage: null,
  customerList: null,
  customerData: null,
  typingSignal: { sender: '', showSignal: false },
  isLoadingCustomerList: false,
  query: {
    queryType: 'customer',
  },
  isGotokeepMsg: false,
  advanceSearchOpen: false,
  selectCustomer: null,
  newFile: null,
  messageByDate: null,
  isLoadingMessage: false,
  // 當前對話視窗typing
  typingSignalUsers: [],
  hasNextPage: false,
  // 行為歷程
  history: [],
  // 最常造訪頁面
  frequentPage: null,
  // 最近開啟對話頁面
  lastConvPage: null,
};

export default (state = initialState, action) => {
  const { customerData, ...others } = state;
  switch (action.type) {
    case actionType.TEST:
      return { ...state, requestId: action.payload };
    case actionType.SET_CUSTOMER:
      return { ...state, ...action.payload };
    case actionType.CUSTOMER_RESET:
      return initialState;
    case actionType.SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case actionType.SET_GOTO_KEEP_MSG:
      return {
        ...state,
        isGotokeepMsg: action.payload,
      };
    case actionType.FIND_CUSTOMER_REQUEST:
      return { ...state, isLoadingCustomerList: true };
    case actionType.FIND_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerList: action.payload,
        isLoadingCustomerList: false,
      };
    case actionType.FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerList: action.payload,
      };
    case actionType.HAS_NEXT_PAGE:
      return {
        ...state,
        hasNextPage: action.payload,
      };
    case actionType.FIND_CUSTOMER_FAILURE:
      return { ...state, isLoadingCustomerList: false };
    case actionType.SET_CUSTOMER_LIST:
      return { ...state, customerList: action.payload };
    case actionType.UPDATE_CUSTOMER:
      return state;
    case actionType.ASSIGN_CUSTOMER:
      return { ...state, assignResult: action.payload };
    case actionType.FIND_CUSTOMER_FROM_CUSTOMERIDS:
      return { ...state, queryCustomers: action.payload };
    case actionType.FIND_CUSTOMER_FROM_CONVERSATION:
      return { ...state, selectCustomer: action.payload };
    case actionType.CUSTOMER_ACCOUNTING_LIST_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isConditionSearch: false,
        isCustomerAccountingListLoading: true,
      };
    case actionType.CUSTOMER_ACCOUNTING_LIST_WITH_CONDITION_SEARCH_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isConditionSearch: true,
        isCustomerAccountingListLoading: true,
      };
    case actionType.CUSTOMER_ACCOUNTING_LIST_SUCCESS:
    case actionType.CUSTOMER_ACCOUNTING_LIST_WITH_CONDITION_SEARCH_SUCCESS:
      return {
        ...state,
        isCustomerAccountingListLoading: false,
        customerAccountingList: action.payload,
      };
    case actionType.CUSTOMER_ACCOUNTING_LIST_FAILURE:
    case actionType.CUSTOMER_ACCOUNTING_LIST_WITH_CONDITION_SEARCH_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        isCustomerAccountingListLoading: false,
        customerAccountingList: [],
      };
    case actionType.CUSTOMER_ACCOUNTING:
      return { ...state, customerAccounting: action.payload };
    case actionType.CLEAR_MESSAGE_HISTORY:
      return { ...state, messageHistory: action.payload };
    case actionType.CLEAR_KEPT_MESSAGE:
      return { ...state, keptMessage: action.payload };
    case actionType.SET_QUERY_MESSAGE_HISTORY_LOADING:
      return { ...state, isLoadingMessage: action.payload };
    case actionType.QUERY_MESSAGE_HISTORY:
      return {
        ...state,
        messageHistory: uniqBy(
          [...state.messageHistory, ...action.payload],
          d => d.objectId,
        ),
      };
    case actionType.ADD_MESSAGE_HISTORY:
      return {
        ...state,
        messageHistory: [...state.messageHistory, action.payload],
      };
    case actionType.QUERY_MESSAGE_BY_DATE:
      return { ...state, messageByDate: action.payload };
    case actionType.RELOAD_KEEP_MESSAGE:
      return { ...state, keptMessage: action.payload };

    case actionType.GET_KEPT_MESSAGE:
      return {
        ...state,
        keptMessage: [...state.keptMessage, ...action.payload],
      };
    case actionType.GET_CUSTOMER_DETAIL_DATA_REQUEST:
      return { ...state, errorMessage: null };
    case actionType.GET_CUSTOMER_DETAIL_DATA_RESET:
      return { ...state, customerData: null, errorMessage: null };
    case actionType.GET_CUSTOMER_DETAIL_DATA_SUCCESS:
      return { ...state, customerData: action.data };
    case actionType.GET_CUSTOMER_DETAIL_DATA_FAILURE:
      return { ...state, errorMessage: action.errorMessage };
    case actionType.GET_CUSTOMER_ACCOUNTING_REQUEST:
      return {
        ...others,
        customerData: {
          ...customerData,
          totalSentMessage: 0,
          totalSessions: 0,
          averageSessionDuration: 0,
          intimacy: 0,
        },
        errorMessage: null,
      };
    case actionType.GET_CUSTOMER_ACCOUNTING_SUCCESS:
      return {
        ...others,
        customerData: {
          ...customerData,
          ...action.data,
        },
        errorMessage: null,
      };
    case actionType.GET_CUSTOMER_ACCOUNTING_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case actionType.GET_LASTREAD:
      return {
        ...state,
        lastread: action.payload,
      };
    case actionType.SET_ADVANCE_SEARCH_OPEN:
      return {
        ...state,
        advanceSearchOpen: action.payload,
      };

    case actionType.SET_NEW_FILE:
      return {
        ...state,
        newFile: action.payload,
      };
    case actionType.SHOW_TYPING_SIGNAL:
      return {
        ...state,
        typingSignalUsers: [
          ...state.typingSignalUsers.filter(
            d => d?.customerId !== action.payload?.customerId,
          ),
          action.payload,
        ],
      };
    case actionType.HIDE_TYPING_SIGNAL:
      return {
        ...state,
        typingSignalUsers: state.typingSignalUsers.filter(
          d => d?.customerId !== action.payload?.customerId,
        ),
      };
    case actionType.SET_DELETE_MESSAGE:
      return {
        ...state,
        delMessage: action.payload,
      };
    default:
      return state;
  }
};
