import jwt from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import axiosInstance, {
  pureAxios,
  serverApiAxios,
  nextAPI,
} from '../common/axios';
import config from '../config';
import actionType from './type';

import { getBrowserLanguage } from '../common/utils';
import i18n from '../i18n';
import ECService from '../services/ECService';
import FileService from '../services/FileService';
import PermissionSettingService from '../services/PermissionSettingService';
import { SnackBarType } from '../types';
import { resetOrganization } from './organizationAction';
import { enqueueSnackbar } from './snackbarAction';

const timeOut = ms => new Promise(resolve => setTimeout(resolve, ms));

export const clearInviteCode = () => {
  localStorage.removeItem(config.INVITE_CODE);
};

export const clearECSrc = () => {
  localStorage.removeItem(config.EC_REFERRAL);
  localStorage.removeItem(config.SHOPURL);
};

export const getInviteCode = () => {
  const localStorageInviteCode = localStorage.getItem(config.INVITE_CODE);
  return localStorageInviteCode ? JSON.parse(localStorageInviteCode) : null;
};

const checkInviteCode = invite => {
  try {
    const decoded = jwt(invite);
    const { exp } = decoded || {};
    return exp && exp * 1000 > Date.now();
  } catch (e) {
    return false;
  }
};

const setInviteCodeByUrl = pathName => () => {
  const params = new URLSearchParams(pathName);
  const invite = params.get('invite_code');
  const email = params.get('email');
  const organization = params.get('organization');
  const isUser = params.get('isuser');

  if (invite) {
    if (checkInviteCode(invite)) {
      const inviteCode = localStorage.getItem(config.INVITE_CODE);
      if (inviteCode) {
        clearInviteCode();
      }

      localStorage.setItem(
        config.INVITE_CODE,
        JSON.stringify({
          invite,
          email,
          organization,
        }),
      );
      return { invite, isUser };
    }
    return { invite };
  }
  return null;
};

export const inviteOrganization = currentUser => async dispatch => {
  const inviteCode = getInviteCode();
  const { invite, organization } = inviteCode || {};

  try {
    const { sessionToken } = currentUser || {};
    if (invite) {
      const response = await axiosInstance.post('/functions/invite', {
        invite_code: invite,
        _SessionToken: sessionToken,
      });

      clearInviteCode();

      await timeOut(3000);

      return response;
    }

    clearInviteCode();

    return null;
  } catch (e) {
    if (e?.response?.data?.error === 'error/invalid-invite-code') {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: i18n.t('sign-up.content.invite-fail', { organization }),
        }),
      );
    } else {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: e?.response?.data?.error || e?.message,
        }),
      );
    }

    clearInviteCode();
    return null;
  }
};

const addFirstDefaultOrganization = currentUser => async dispatch => {
  const inviteCode = getInviteCode();
  try {
    if (!inviteCode) {
      const { industry, company, sessionToken, locale } = currentUser;

      if (industry && company && sessionToken) {
        const response = await axiosInstance.post('/classes/Organization', {
          name: uuidv4(),
          industry,
          locale: locale || getBrowserLanguage(),
          displayName: company,
          _SessionToken: sessionToken,
        });

        return response;
      }
    } else {
      return await dispatch(inviteOrganization(currentUser));
    }

    clearInviteCode();

    return null;
  } catch (e) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: e?.response?.data?.error || e?.message,
      }),
    );

    return e;
  }
};

export const getCurrentUserByLocalStorage = () => {
  const localStorageCurrentUser = localStorage.getItem(config.LOCAL_STORAGE);
  const currentUser = localStorageCurrentUser
    ? JSON.parse(localStorageCurrentUser)
    : null;

  return currentUser;
};

export const getCurrentUser = async isForce => {
  const currentUser = getCurrentUserByLocalStorage();

  if (currentUser && isForce) {
    const userData = await axiosInstance.post(
      `/classes/_User/${currentUser?.objectId}`,
      {
        _method: 'GET',
      },
    );
    return {
      ...currentUser,
      ...userData,
    };
  }
  return currentUser;
};

export const logInStorage = userData => {
  localStorage.setItem(config.LOCAL_STORAGE, JSON.stringify(userData));
};

const logOut = () => dispatch => {
  localStorage.removeItem(config.LOCAL_STORAGE);
  dispatch(resetOrganization());
};

const fetchUser = isForce => async dispatch => {
  const currentUser = await getCurrentUser(isForce);
  if (currentUser) {
    dispatch({ type: actionType.FETCH_USER, payload: currentUser });
    if (isForce) logInStorage(currentUser);
    return currentUser;
  }

  dispatch(logOut());
  return null;
};

const setPasswordLevel = passwordLevel => async dispatch => {
  dispatch({ type: actionType.SET_PASSWORD_LEVEL, payload: passwordLevel });
};

const sendEmailVerified = (userData, isChangeEmail) => async dispatch => {
  try {
    const { objectId, email, sessionToken } = userData;

    if (objectId && email) {
      await axiosInstance.post(`/classes/_User/${objectId}`, {
        email,
        userEmail: email,
        username: email,
        _SessionToken: sessionToken,
        _method: 'PUT',
      });

      dispatch({
        type: actionType.FETCH_USER,
        payload: userData,
      });

      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: isChangeEmail
            ? i18n.t('sign-up.send-change-email-verified')
            : i18n.t('sign-up.send-email-verified'),
        }),
      );
    } else {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: i18n.t('sign-up.send-email-verified-error'),
        }),
      );

      return true;
    }
    return false;
  } catch (e) {
    if (
      e?.response?.data?.error === 'Account already exists for this username.'
    ) {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: i18n.t('sign-up.account-already-exists'),
        }),
      );
    } else {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: e?.response?.data?.error || e?.message,
        }),
      );
    }
    return false;
  }
};

export const cyberbizSendEmail = (
  orgId,
  isEnableProvider,
  _SessionToken,
) => async dispatch => {
  try {
    const referral = localStorage.getItem(config.EC_REFERRAL);
    const shopUrl = localStorage.getItem(config.SHOPURL);
    if (referral === 'cyberbiz') {
      await ECService.cyberbizSendEmailAndEnable(orgId, isEnableProvider, {
        _SessionToken,
      });

      if (isEnableProvider) {
        await ECService.connectEC(orgId, 'cyberbiz', {
          shopUrl,
        });

        clearECSrc();
        window.location.href = `${shopUrl}/admin/add_ons`;
      }

      localStorage.removeItem(config.LOCAL_STORAGE);
      clearECSrc();
    }
  } catch (error) {
    if (error?.response?.data?.code === 'ECRetargetingDomainNotAccept') {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: i18n.t(
            'superMarket:cyberbizRetargetingDomainNotAcceptError',
          ),
        }),
      );
    } else if (
      error?.response?.data?.code === 'AlreadyEnableUat' ||
      error?.response?.data?.code === 'AlreadyBuyProvider'
    ) {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: i18n.t('superMarket:cyberbizAlreadyEnableUatError'),
        }),
      );
    } else {
      localStorage.removeItem(config.LOCAL_STORAGE);
    }
  }
};

export const shoplineConnectWithApp = (
  orgId,
  _SessionToken,
) => async dispatch => {
  try {
    const url = await ECService.shoplineConnectWithApp(orgId, _SessionToken);

    if (url) {
      clearECSrc();
      setTimeout(() => {
        window.location.href = url;
      }, 500);
    } else {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: 'url not found',
        }),
      );
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error,
      }),
    );
  } finally {
    clearECSrc();
    localStorage.removeItem(config.LOCAL_STORAGE);
  }
};

const normalSignup = attrs => async dispatch => {
  try {
    const currentUser = getCurrentUser();
    if (currentUser) {
      logOut();
      dispatch({
        type: actionType.USER_LOGOUT,
      });
    }

    const response = await axiosInstance.post('/users', attrs);
    dispatch({
      type: actionType.NORMAL_SIGNUP,
      payload: { ...response, ...attrs },
    });

    await new Promise(resolve => setTimeout(resolve, 5000));

    // 註冊時新增組織
    const org = await dispatch(
      addFirstDefaultOrganization({ ...response, ...attrs }),
    );

    await dispatch(
      cyberbizSendEmail(org?.objectId, false, response?.sessionToken),
    );

    clearInviteCode();
    clearECSrc();

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('sign-up.send-email-verified'),
      }),
    );

    return response;
  } catch (e) {
    return e;
  }
};

const normalLogin = (email, password) => async dispatch => {
  try {
    const currentUser = getCurrentUser();
    if (currentUser) {
      logOut();
      dispatch({
        type: actionType.USER_LOGOUT,
      });
    }

    const response = await nextAPI.post('/user/login', {
      username: email,
      password,
      _method: 'GET',
    });

    logInStorage(response);

    await dispatch(inviteOrganization(response));

    dispatch({ type: actionType.NORMAL_LOGIN, payload: response });

    return response;
  } catch (e) {
    dispatch({ type: actionType.USER_LOGOUT });
    return e;
  }
};

const socialLogin = (provider, authData) => async dispatch => {
  try {
    const currentUser = getCurrentUser();
    if (currentUser) {
      dispatch(logOut());
    }

    const query = {
      authData: {},
    };
    query.authData[provider] = authData;

    const response = await pureAxios.post('/users', query);

    if (response?.status === 200 && response?.data && response?.data?.email) {
      logInStorage(response?.data);
      await dispatch(inviteOrganization(response?.data));

      dispatch({
        type: actionType.SOCIAL_LOGIN,
        payload: response?.data,
      });

      return response;
    }
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: i18n.t('sign-up.account-undefined'),
      }),
    );
    return null;
  } catch (e) {
    if (
      e?.response?.data?.error ===
        'Account already exists for this email address.' ||
      e?.response?.data?.error === 'Account already exists for this username.'
    ) {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: i18n.t('sign-up.account-already-exists'),
        }),
      );
    } else {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: e?.response?.data?.error || e?.message,
        }),
      );
    }
    return e;
  }
};

const userLogout = () => dispatch => {
  dispatch(logOut());
  dispatch({
    type: actionType.USER_LOGOUT,
  });
};

const sendPasswordEmail = email => async dispatch => {
  try {
    await axiosInstance.post('/requestPasswordReset', {
      email: email.toLowerCase(),
    });
    dispatch({ type: actionType.SEND_PASSWORD, payload: true });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('sign-up.send-password-email'),
      }),
    );
    return true;
  } catch (e) {
    let message = null;
    if (e?.response) {
      switch (e?.response?.data?.code) {
        case 205:
          message = i18n.t('forget-password.no-member');
          break;
        default:
          message = i18n.t('forget-password.email-error');
          break;
      }
    } else {
      message = e.message;
    }
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message,
      }),
    );
    return false;
  }
};

const closePasswordSendTag = () => ({
  type: actionType.CLOSE_PASSWORD_TAG,
  payload: false,
});

const getPermission = () => async (dispatch, getState) => {
  const { userData } = getState().auth;

  const query = {
    where: {
      owner: {
        __type: 'Pointer',
        className: '_User',
        objectId: userData?.objectId,
      },
    },
    limit: 1,
    _method: 'GET',
  };

  const { results } = await axiosInstance.post('/classes/Permission', query);
  dispatch({ type: actionType.GET_PERMISSION, payload: results[0] });
};

const getUserOrgFeature = orgId => async dispatch => {
  const res = await PermissionSettingService.getUserOrgFeature(orgId);
  dispatch({ type: actionType.SET_USER_ORG_FEATURE, payload: res });
};

const resetUserOrgFeature = () => dispatch => {
  dispatch({ type: actionType.SET_USER_ORG_FEATURE, payload: null });
};

const getOrder = (orderId, isGetNextOrder = false) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.GET_ORDER_REQUEST, isGetNextOrder });
    serverApiAxios({
      url: `/billing/api/v2/order/${orderId}`,
    })
      .then(response => {
        dispatch({
          type: actionType.GET_ORDER_SUCCESS,
          data: response.data,
          isGetNextOrder,
        });
        resolve(response.data);
      })
      .catch(errorMessage => {
        dispatch({ type: actionType.GET_ORDER_FAILURE, isGetNextOrder });
        // eslint-disable-next-line no-console
        console.error(errorMessage);
        reject(errorMessage);
      });
  });

const getPlans = () => dispatch => {
  dispatch({ type: actionType.GET_PLANS_REQUEST });
  serverApiAxios({
    url: '/billing/api/v2/plan?version=2&is_public=true',
  })
    .then(response => {
      dispatch({ type: actionType.GET_PLANS_SUCCESS, data: response.data });
    })
    .catch(errorMessage => {
      dispatch({ type: actionType.GET_PLANS_FAILURE });
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: errorMessage,
        }),
      );
    });
};

const getAddons = () => dispatch => {
  dispatch({ type: actionType.GET_ADDONS_REQUEST });
  serverApiAxios({
    url: '/billing/api/v2/addon',
  })
    .then(response => {
      dispatch({
        type: actionType.GET_ADDONS_SUCCESS,
        data: response.data.map(d => ({
          ...d,
          name: i18n.t(`account-setting.addons.${d.id}-name`),
          description: i18n.t(`account-setting.addons.${d.id}-description`),
        })),
      });
    })
    .catch(errorMessage => {
      dispatch({ type: actionType.GET_ADDONS_FAILURE });
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: errorMessage,
        }),
      );
    });
};

const getCurrentUserInfo = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.GET_USER_INFO_REQUEST });
    serverApiAxios({
      url: `/billing/api/v2/user/${getCurrentUserByLocalStorage().objectId}`,
    })
      .then(response => {
        dispatch({
          type: actionType.GET_USER_INFO_SUCCESS,
          data: response.data,
        });
        resolve(response.data);
      })
      .catch(errorMessage => {
        dispatch({ type: actionType.GET_USER_INFO_FAILURE });
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: errorMessage,
          }),
        );
        reject(errorMessage);
      });
  });

const updateUserInfo = data => async dispatch => {
  dispatch({ type: actionType.UPDATE_USER_INFO_REQUEST });
  try {
    const response = await serverApiAxios({
      url: `/billing/api/v2/user/${getCurrentUserByLocalStorage().objectId}`,
      method: 'patch',
      data: {
        name: '',
        email: '',
        zip: '',
        address: '',
        ubn: '',
        title: '',
        ...data,
      },
    });

    dispatch({
      type: actionType.UPDATE_USER_INFO_SUCCESS,
      data: response.data,
    });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('user-info.save-success'),
      }),
    );
  } catch (errorMessage) {
    dispatch({ type: actionType.UPDATE_USER_INFO_FAILURE });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: errorMessage,
      }),
    );
  }
};

const createPaymentMethod = userId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.CREATE_PAYMENT_METHOD_REQUEST });
    serverApiAxios({
      url: '/billing/api/v2/paymentmethod',
      method: 'post',
      data: {
        user_id: userId,
        type: 'credit',
        is_default: true,
      },
    })
      .then(response => {
        dispatch({
          type: actionType.CREATE_PAYMENT_METHOD_SUCCESS,
          data: response.data,
        });
        resolve(response.data);
      })
      .catch(errorMessage => {
        dispatch({ type: actionType.CREATE_PAYMENT_METHOD_FAILURE });
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: errorMessage,
          }),
        );
        reject(errorMessage);
      });
  });

const getPaymentMethods = (userId, options) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.GET_PAYMENT_METHODS_REQUEST });
    serverApiAxios({
      url: '/billing/api/v2/paymentmethod',
      params: {
        user_id: userId,
        ...options,
      },
    })
      .then(response => {
        dispatch({
          type: actionType.GET_PAYMENT_METHODS_SUCCESS,
          data: response.data,
        });
        resolve(response.data);
      })
      .catch(errorMessage => {
        dispatch({ type: actionType.GET_PAYMENT_METHODS_FAILURE });
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: errorMessage,
          }),
        );
        reject(errorMessage);
      });
  });

const getPaymentMethod = paymentMethodId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.GET_PAYMENT_METHOD_REQUEST });
    serverApiAxios({
      url: `/billing/api/v2/paymentmethod/${paymentMethodId}`,
    })
      .then(response => {
        dispatch({
          type: actionType.GET_PAYMENT_METHOD_SUCCESS,
          data: response.data,
        });
        resolve(response.data);
      })
      .catch(errorMessage => {
        dispatch({ type: actionType.GET_PAYMENT_METHOD_FAILURE });
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: errorMessage,
          }),
        );
        reject(errorMessage);
      });
  });

const bindCreditCard = paymentMethodId => dispatch =>
  new Promise((resolve, reject) => {
    serverApiAxios({
      url: `/billing/api/v2/paymentmethod/${paymentMethodId}/auth`,
      params: {
        redirectTo: `${window.location.origin}/account-setting/plan-info/bind-credit-card-result/${paymentMethodId}`,
      },
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(errorMessage => {
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: errorMessage,
          }),
        );
        reject(errorMessage);
      });
  });

const getPayInfo = paymentId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.GET_PAY_INFO_REQUEST });
    serverApiAxios({
      url: `/billing/api/v2/payment/${paymentId}/pay`,
      params: {
        redirectTo: `${window.location.origin}/account-setting/plan-info/payment-result/${paymentId}`,
      },
    })
      .then(response => {
        dispatch({
          type: actionType.GET_PAY_INFO_SUCCESS,
          data: response.data,
        });
        resolve(response.data);
      })
      .catch(errorMessage => {
        dispatch({ type: actionType.GET_PAY_INFO_FAILURE });
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: errorMessage,
          }),
        );
        reject(errorMessage);
      });
  });

const createPayment = orderId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.CREATE_PAYMENT_REQUEST });
    serverApiAxios({
      url: '/billing/api/v2/payment',
      method: 'post',
      data: {
        order_id: orderId,
      },
    })
      .then(response => {
        dispatch({ type: actionType.CREATE_PAYMENT_SUCCESS });
        resolve(response.data);
      })
      .catch(errorMessage => {
        dispatch({ type: actionType.CREATE_PAYMENT_FAILURE });
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: errorMessage,
          }),
        );
        reject(errorMessage);
      });
  });

const createOrder = (userId, options) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.CREATE_ORDER_REQUEST, dryRun: options.dryRun });
    const orderOptions = {
      plan_id: '',
      period_by_days: 0,
      is_auto_renew: false,
      payment_method_id: -1,
      addon_ids: null,
      ...options,
    };
    serverApiAxios({
      url: '/billing/api/v2/order',
      method: 'post',
      data: {
        user_id: userId,
        ...orderOptions,
      },
    })
      .then(response => {
        dispatch({
          type: actionType.CREATE_ORDER_SUCCESS,
          data: response.data,
          dryRun: options.dryRun,
        });
        resolve(response.data);
      })
      .catch(error => {
        dispatch({
          type: actionType.CREATE_ORDER_FAILURE,
          dryRun: options.dryRun,
        });
        if (error.code === 'E_CONFLICT') {
          const resource = i18n.t(
            `account-setting.addons.${error.data.resourceId}-name`,
          );
          const { used, quota } = error.data;

          dispatch(
            enqueueSnackbar({
              variant: SnackBarType.ERROR,
              message: i18n.t('account-setting.addons.usedNotEnough', {
                resource,
                used,
                quota,
              }),
            }),
          );
        } else {
          dispatch(
            enqueueSnackbar({
              variant: SnackBarType.ERROR,
              message: error,
            }),
          );
        }
        reject(error);
      });
  });

const getPayment = paymentId => dispatch => {
  dispatch({ type: actionType.GET_PAYMENT_REQUEST });
  serverApiAxios({
    url: `/billing/api/v2/payment/${paymentId}`,
  })
    .then(response => {
      dispatch({ type: actionType.GET_PAYMENT_SUCCESS, data: response.data });
    })
    .catch(errorMessage => {
      dispatch({ type: actionType.GET_PAYMENT_FAILURE });
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: errorMessage,
        }),
      );
    });
};

const cancelNextOrder = orderId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.CANCEL_NEXT_ORDER_REQUEST });
    serverApiAxios({
      url: `/billing/api/v2/order/${orderId}/cancel`,
      method: 'post',
    })
      .then(response => {
        dispatch({
          type: actionType.CANCEL_NEXT_ORDER_SUCCESS,
          data: response.data,
        });
        resolve();
      })
      .catch(errorMessage => {
        dispatch({ type: actionType.CANCEL_NEXT_ORDER_FAILURE });
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: errorMessage,
          }),
        );
        reject(errorMessage);
      });
  });

const updateOrder = (orderId, options) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.UPDATE_ORDER_REQUEST });
    serverApiAxios({
      url: `/billing/api/v2/order/${orderId}`,
      method: 'patch',
      data: options,
    })
      .then(response => {
        dispatch({
          type: actionType.UPDATE_ORDER_SUCCESS,
          data: response.data,
        });
        resolve();
      })
      .catch(errorMessage => {
        dispatch({ type: actionType.UPDATE_ORDER_FAILURE });
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: errorMessage,
          }),
        );
        reject(errorMessage);
      });
  });

const getReceipts = (userId, options) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.GET_RECEIPTS_REQUEST });
    serverApiAxios({
      url: '/billing/api/v2/receipt',
      params: {
        user_id: userId,
        ...options,
      },
    })
      .then(response => {
        dispatch({
          type: actionType.GET_RECEIPTS_SUCCESS,
          receipts: response.data,
          receiptPaginate: response.meta,
        });
        resolve();
      })
      .catch(errorMessage => {
        dispatch({ type: actionType.GET_RECEIPTS_FAILURE });
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: errorMessage,
          }),
        );
        reject(errorMessage);
      });
  });

const getReceipt = receiptId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.GET_RECEIPT_REQUEST });
    serverApiAxios({
      url: `/billing/api/v2/receipt/${receiptId}`,
    })
      .then(response => {
        response.data.detail.addons = response.data.detail.addons.map(d => ({
          ...d,
          name: i18n.t(`account-setting.addons.${d.id}-name`),
          description: i18n.t(`account-setting.addons.${d.id}-description`),
        }));

        response.data.detail.plan.name = i18n.t(
          `plan-type.${response.data.detail.plan.id}`,
        );
        dispatch({
          type: actionType.GET_RECEIPT_SUCCESS,
          data: response.data,
        });
        resolve();
      })
      .catch(errorMessage => {
        dispatch({ type: actionType.GET_RECEIPT_FAILURE });
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: errorMessage,
          }),
        );
        reject(errorMessage);
      });
  });

const gerResource = userId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({ type: actionType.GET_RESOURCE_REQUEST });
    serverApiAxios({
      url: `/billing/api/v2/user/${userId}/resource`,
    })
      .then(response => {
        dispatch({
          type: actionType.GET_RESOURCE_SUCCESS,
          data: response.data,
        });
        resolve(response.data);
      })
      .catch(errorMessage => {
        dispatch({ type: actionType.GET_RESOURCE_FAILURE });
        dispatch(
          enqueueSnackbar({
            variant: SnackBarType.ERROR,
            message: 'gerResource error!!',
          }),
        );
        reject(errorMessage);
      });
  });

export const setToturialDisabled = isDisabled => async (dispatch, getState) => {
  try {
    const { userData } = getState().auth;

    if (userData) {
      await axiosInstance.post(`/classes/_User/${userData?.objectId}`, {
        toturialDisabled: isDisabled,
        _SessionToken: userData?.sessionToken,
        _method: 'PUT',
      });

      dispatch({
        type: actionType.FETCH_USER,
        payload: {
          ...userData,
          toturialDisabled: true,
        },
      });

      logInStorage({
        ...userData,
        toturialDisabled: true,
      });
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error?.response?.data?.error ?? error.message,
      }),
    );
  }
};

export const saveUserData = userData => async dispatch => {
  try {
    dispatch({ type: actionType.SET_AUTH_ISLOADING, payload: true });

    const currentUser = getCurrentUserByLocalStorage();
    if (currentUser) {
      let picture = userData?.picture;

      if (userData?.picture) {
        const { url } = await FileService.upload(userData.picture);
        picture = url;
      }

      await axiosInstance.post(`/classes/_User/${currentUser?.objectId}`, {
        ...userData,
        ...(picture === '' ? { picture: currentUser?.picture } : { picture }),
        _method: 'PUT',
      });

      await dispatch(fetchUser(true));

      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: i18n.t('user-info.save-success'),
        }),
      );
    }

    dispatch({ type: actionType.SET_AUTH_ISLOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionType.SET_AUTH_ISLOADING, payload: false });
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
  }
};

export const changePassword = (oldPassword, newPassword) => async (
  dispatch,
  getState,
) => {
  try {
    const { userData } = getState().auth;

    const response = await axiosInstance.post('/login', {
      username: userData?.username.toLowerCase(),
      password: oldPassword,
      _method: 'GET',
    });

    if (response?.objectId) {
      await axiosInstance.post(`/classes/_User/${response?.objectId}`, {
        password: newPassword,
        _SessionToken: response?.sessionToken,
        _method: 'PUT',
      });

      await dispatch(userLogout());

      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: i18n.t('password-reset.change-password-success'),
        }),
      );

      return 'done';
    }

    return response;
  } catch (error) {
    if (error?.response?.data?.error === 'Invalid username/password.') {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: i18n.t('password-reset.login-error'),
        }),
      );
    } else if (
      error?.response?.data?.error !==
      'Password does not meet the Password Policy requirements.'
    ) {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: error?.response?.data?.error ?? error.message,
        }),
      );
    }
    return error?.response?.data?.error ?? error.message;
  }
};

export {
  addFirstDefaultOrganization,
  bindCreditCard,
  cancelNextOrder,
  closePasswordSendTag,
  createOrder,
  createPayment,
  createPaymentMethod,
  fetchUser,
  gerResource,
  getAddons,
  getCurrentUserInfo,
  getOrder,
  getPayInfo,
  getPayment,
  getPaymentMethod,
  getPaymentMethods,
  getPermission,
  getPlans,
  getReceipt,
  getReceipts,
  getUserOrgFeature,
  normalLogin,
  normalSignup,
  resetUserOrgFeature,
  sendEmailVerified,
  sendPasswordEmail,
  setInviteCodeByUrl,
  setPasswordLevel,
  socialLogin,
  updateOrder,
  updateUserInfo,
  userLogout,
};
