import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MUITooltip from '@material-ui/core/Tooltip';
import { color } from '../../themes';

const useStyles = makeStyles(
  theme => ({
    tooltip: {
      ...theme.typography.tooltip,
      position: 'relative',
      backgroundColor: color.$3,
      padding: '12px 12px 11px 12px',
      borderRadius: '12px',
      maxWidth: '254px',
      lineHeight: 1,
    },
    tooltipLight: {
      ...theme.typography.tooltip,
      position: 'relative',
      backgroundColor: color.$7,
      padding: '16px',
      borderRadius: '12px',
      maxWidth: '300px',
      boxShadow:
        '0 16px 48px 0 rgba(0, 0, 0, 0.07), 0 6px 18px 0 rgba(0, 0, 0, 0.03)',
      color: color.$12,
      lineHeight: 'normal',
    },
    arrow: {
      position: 'absolute',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
    popper: {
      zIndex: 9999,
      '&[x-placement="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-7px',
        '&::before': {
          borderWidth: '0 6px 7px 6px',
          borderColor: `transparent transparent ${color.$3} transparent`,
        },
      },
      '&[x-placement="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-7px',
        '&::before': {
          borderWidth: '7px 6px 0 6px',
          borderColor: `${color.$3} transparent transparent transparent`,
        },
      },
      '&[x-placement="right"] $arrow': {
        left: 0,
        marginLeft: '-7px',
        '&::before': {
          borderWidth: '6px 7px 6px 0',
          borderColor: `transparent ${color.$3} transparent transparent`,
        },
      },
      '&[x-placement="left"] $arrow': {
        right: 0,
        marginRight: '-7px',
        '&::before': {
          borderWidth: '6px 0px 6px 7px',
          borderColor: `transparent transparent transparent ${color.$3}`,
        },
      },
    },
  }),
  {
    name: 'Tooltip',
  },
);

const Tooltip = ({
  light,
  detailClasses,
  titleClass,
  isComponent,
  title,
  children,
  ...otherProps
}) => {
  const classes = useStyles();

  const [arrowRef, setArrowRef] = useState(null);

  const handleArrowRef = node => {
    setArrowRef(node);
  };

  return (
    <MUITooltip
      classes={{
        popper: classnames(classes.popper, detailClasses.popper),
        popperInteractive: classnames(detailClasses.popperInteractive),
        popperArrow: classnames(detailClasses.popperArrow),
        tooltip: classnames(
          light ? classes.tooltipLight : classes.tooltip,
          detailClasses.tooltip,
        ),
        tooltipArrow: classnames(detailClasses.tooltipArrow),
        arrow: classnames(detailClasses.arrow),
        touch: classnames(detailClasses.touch),
        tooltipPlacementLeft: classnames(detailClasses.tooltipPlacementLeft),
        tooltipPlacementRight: classnames(detailClasses.tooltipPlacementRight),
        tooltipPlacementTop: classnames(detailClasses.tooltipPlacementTop),
        tooltipPlacementBottom: classnames(
          detailClasses.tooltipPlacementBottom,
        ),
      }}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: light ? false : Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
      {...otherProps}
      title={
        <>
          {isComponent ? (
            title
          ) : (
            <div
              className={titleClass}
              dangerouslySetInnerHTML={{ __html: title || '' }}
            />
          )}
          {!light && <span className={classes.arrow} ref={handleArrowRef} />}
        </>
      }
    >
      {children}
    </MUITooltip>
  );
};

Tooltip.defaultProps = {
  light: false,
  detailClasses: {},
  titleClass: undefined,
};

Tooltip.propTypes = {
  light: PropTypes.bool,
  detailClasses: PropTypes.shape({
    popper: PropTypes.string,
    popperInteractive: PropTypes.string,
    popperArrow: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipArrow: PropTypes.string,
    arrow: PropTypes.string,
    touch: PropTypes.string,
    tooltipPlacementLeft: PropTypes.string,
    tooltipPlacementRight: PropTypes.string,
    tooltipPlacementTop: PropTypes.string,
    tooltipPlacementBottom: PropTypes.string,
  }),
  titleClass: PropTypes.string,
};

export default Tooltip;
