import _escape from 'lodash/escape';
import { HASHTAG, showText } from '../../common/utils';
import i18n from '../../i18n';

export const HashtagSpan = ({ children }) => {
  return (
    <span className="hashTag" contentEditable={false}>
      {i18n.t('broadcast:customerName')}
      <span style={{ display: 'none' }}>{children}</span>
    </span>
  );
};

export const replaceHashtagText = (data = '') => {
  const HASHTAG_REGEX = new RegExp(_escape(HASHTAG), 'g');

  return data.replace(
    HASHTAG_REGEX,
    `<span class="hashTag">${i18n.t('broadcast:customerName')}</span>`,
  );
};

export const HashtagText = (data = '') => {
  return replaceHashtagText(_escape(data));
};

export const HashtagUrlText = data => {
  return replaceHashtagText(showText(data));
};

export default HashtagText;
