const common = {
  INTEGRETION_SECRET: 'secret',
  DEFAULT_TEMPLATE_EXPIRED_DAY: 7,
  ALL_CUSTOMERS_FIRST_LIMIT_NUMBER: 40,
  ALL_CUSTOMERS_LIMIT_NUMBER: 20,
  ALL_CUSTOMERS_QUERY_DELAY: 200,
  DEBOUNCE_DELAY: 600,
  TAG_MANAGEMENT_DEFAULT_QUERY_LIMIT: 40,
  TAG_MANAGEMENT_CONTINUE_QUERY_LIMIT: 20,
  MAX_CARD_TITLE_LENGTH: 40,
  MAX_CARD_SUBTITLE_LENGTH: 60,
  MAX_BUTTON_TITLE_LENGTH: 20,
  MAX_TASK_LIMIT: 15,
  IS_SIDEBAR_EXPAND_NAME: 'isSidebarExpand',
  SELECTED_ORG_ID: 'selectedOrganizationId',
  IS_OPEN_LINE_EVENT: false,
  FACEBOOK_APP_REQUIRED_SCOPE:
    'email, pages_show_list, pages_messaging, pages_manage_ads, pages_manage_metadata, pages_read_engagement, pages_read_user_content, pages_manage_posts, pages_manage_engagement, business_management',
  INSTAGRAM_APP_REQUIRED_SCOPE:
    'email, pages_show_list, pages_messaging, instagram_basic, instagram_manage_comments, instagram_manage_messages, pages_read_engagement, pages_manage_metadata, pages_read_user_content, pages_manage_ads, pages_manage_posts, pages_manage_engagement, public_profile, business_management',
  AUTH_TYPE: 'rerequest',
  AIRBRAKE_PROJECT_ID: '168192',
  AIRBRAKE_PROJECT_KEY: '09e394e8b287e8cecf0f5dc9e77448d2',
  PARSE_JAVASCRIPT_KEY: 'javascriptKey',
  DEFAULT_LANGUAGE: 'zh-TW',
  VERSION: 'Version 2.0.0',
  TUTORIAL_META_URL: 'assets/metadata/tutorial.json',
  MESSAGEBOT_META_URL: 'assets/metadata/messagebot.prod.json',
  MESSAGEBOT_EDITOR_LOADING:
    'https://s3-ap-northeast-1.amazonaws.com/assets.no8.io/docs/website/loading.html',
  ADDON_UNAVAIABLE_MAIL: 'sales@no8.io',
  INVITE_CODE: 'invite_code',
  EC_REFERRAL: 'ecReferral',
  SHOPURL: 'shopUrl',
  // enable this ONLY when system uses the new chat-proxy
  DEFAULT_DOWNLOAD_COUPON_EXAMPLE_FILE:
    'https://downloads.no8.io/examples/game-module/import_coupon_example.csv',
  DEFAULT_LIVECHAT_LOGO_EXAMPLE_FILE:
    'https://assets.no8.io/live_chat/default_logo.png',
  TITLE: 'MessageHero | ',
  MAX_BROADCAST_LENGTH: 5,
  WHATSAPP_LOGO:
    'https://s3.ap-northeast-1.amazonaws.com/assets.no8.io/engagelab/logo-super-8-color-244-48.png',
  HUB_SPOT_URL: 'https://api.hsforms.com',
  SESSION_INBOX: 'SESSION_INBOX',
  SESSION_FILE_TAB_INDEX: 'SESSION_FILE_TAB_INDEX',
  SESSION_PLATFORM: 'SESSION_PLATFORM',
  SESSION_SEARCH_MODE: 'SESSION_SEARCH_MODE',
  CHATPAY_PRODUCT_UPLOAD_TEMPLATE:
    'https://downloads.no8.io/examples/chat-pay/products_template.csv',
  NOTE_TEXT_LIMIT: 300,
  TYPING_TIME: 5000,
  SUPER8_API_KEY_LENGTH: 20,
};

const stag = {
  MAX_INBOX_UPDATE_CUSTOMER_COUNT: 5,
  PNP_URL: 'https://t.no8.io/infobip',
  MA_SERVER_URL: 'https://stage-api-next.no8.io',
  PARSE_SERVER_URL: 'https://stage-api-live-query.no8.io',
  PARTNER_TAG_SERVER_URL: 'https://t.no8.io/gss-vendor-api',
  NEXT_API_URL: 'https://stage-api-next.no8.io',
  NEXT_API_SHORT_URL: 'https://tn.no8.io',
  SOCKET_SERVER_URL: 'wss://stage-ws.no8.io',
  PARSE_APP_ID: 'number8_stage',
  GOOGLE_CLIENT_ID:
    '839711338673-gh9crt4mp8v2rdsdr0grltnqequthvu6.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'zcAhtvJxp7bzppoK8WyhWtKy',
  FACEBOOK_APP_ID: '936596570316202',
  LINE_CHANNEL_INFO_URL: 'https://t.no8.io/line/channel_info',
  INTEGRATION_WEBHOOK_URL: 'https://t.no8.io/line/',
  BOT_WEBHOOK_URL: 'https://t.no8.io/bot/',
  META_TAG_CRAWLER_URL: 'https://t.no8.io/crawl',
  GA_TRACKING_ID: 'UA-110414107-3',
  LOCAL_STORAGE: 'Parse/number8_stage/currentUser',
  CONSOLE_MIDDLE_URL: 'https://stage-console-middle.no8.io',
  GROWTH_TOOL_API_URL: 'https://stage-growth-tool-api.no8.io',
  RICH_MENU_API_URL: 'https://stage-rich-menu-editor.no8.io',
  GAME_API_URL: 'https://t.no8.io/game-module-api',
  LANGUAGE_VALUE: ['zh-TW', 'ja-JP', 'zh-CN', 'en-US'],
  SUPPORT_BROWSER_LANGUAGE: ['zh-TW', 'ja-JP', 'zh-CN', 'en-US'],
  UPLOAD_URL: 'https://t.no8.io/uploader/files',
  SENTRY_DSN: '',
  NOT_NEXT_BROADCAST_QUICK_REPLY: [],
  COUPON_SERVER_URL: 'https://stage-s.no8.io',
  EC_DOMAIN_URL: 'https://stage-supermarket.no8.io',
  LIVECHAT_STYLE_URL: 'https://assets.no8.io/stage/live_chat/style',
  LIVECHAT_CONSOLE_URL: 'https://stage-live-chat-console.no8.io',
  RETARGETING_GTMSETTING_JSON_FILE_DOWNLOAD_URL:
    'https://downloads.no8.io/ec/retargeting/stag/S8_retargeting.json',
  GA4_GTM_URL:
    'https://s3.ap-northeast-1.amazonaws.com/downloads.no8.io/ec/ga4event/stag/S8_GA4Event.json',
  WHATSAPP_DEV_KEY: '63f82e2b3b9d2959d5780fed',
  MA_DOMAIN__URL: 'https://stage-marketing-automation.no8.io',
  GAME_DOMAIN__URL: 'https://stage-game-module.no8.io',
};

const prod = {
  MAX_INBOX_UPDATE_CUSTOMER_COUNT: 50,
  PNP_URL: 'https://n.no8.io/infobip',
  MA_SERVER_URL: 'https://api-next.no8.io',
  PARSE_SERVER_URL: 'https://prod-api-lq.no8.io',
  PARTNER_TAG_SERVER_URL: 'https://n.no8.io/gss-vendor-api',
  NEXT_API_URL: 'https://api-next.no8.io',
  NEXT_API_SHORT_URL: 'https://nn.no8.io',
  SOCKET_SERVER_URL: 'wss://ws.no8.io',
  PARSE_APP_ID: 'number8',
  GOOGLE_CLIENT_ID:
    '294306246828-e87u1h979obd2ov0j2pdqmnn5k1tcqtv.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyCFvjGxZIyglsL37uPaE1-gpGjCf_dF1JA',
  FACEBOOK_APP_ID: '178270832637531',
  LINE_CHANNEL_INFO_URL: 'https://n.no8.io/line/channel_info',
  INTEGRATION_WEBHOOK_URL: 'https://n.no8.io/line/',
  BOT_WEBHOOK_URL: 'https://n.no8.io/bot/',
  META_TAG_CRAWLER_URL: 'https://n.no8.io/crawl',
  GA_TRACKING_ID: 'UA-110414107-2',
  LOCAL_STORAGE: 'Parse/number8/currentUser',
  CONSOLE_MIDDLE_URL: 'https://console-middle.no8.io',
  GROWTH_TOOL_API_URL: 'https://growth-tool-api.no8.io',
  RICH_MENU_API_URL: 'https://rich-menu-editor.no8.io',
  GAME_API_URL: 'https://n.no8.io/game-module-api',
  LANGUAGE_VALUE: ['zh-TW', 'ja-JP', 'zh-CN', 'en-US'],
  SUPPORT_BROWSER_LANGUAGE: ['zh-TW', 'ja-JP', 'zh-CN', 'en-US'],
  UPLOAD_URL: 'https://n.no8.io/uploader/files',
  SENTRY_DSN: '',
  NOT_NEXT_BROADCAST_QUICK_REPLY: ['cJOr2AwKTx'],
  COUPON_SERVER_URL: 'https://s.no8.io',
  EC_DOMAIN_URL: 'https://supermarket.no8.io',
  LIVECHAT_STYLE_URL: 'https://assets.no8.io/prod/live_chat/style',
  LIVECHAT_CONSOLE_URL: 'https://live-chat-console.no8.io',
  RETARGETING_GTMSETTING_JSON_FILE_DOWNLOAD_URL:
    'https://downloads.no8.io/ec/retargeting/prod/S8_retargeting.json',
  GA4_GTM_URL:
    'https://s3.ap-northeast-1.amazonaws.com/downloads.no8.io/ec/ga4event/prod/S8_GA4Event.json',
  WHATSAPP_DEV_KEY: '642a669ac7d5672c9d803c13',
  MA_DOMAIN__URL: 'https://marketing-automation.no8.io',
  GAME_DOMAIN__URL: 'https://game-module.no8.io',
};

const config =
  process.env.REACT_APP_STAGE === 'production'
    ? { ...common, ...prod }
    : { ...common, ...stag };

export default config;
