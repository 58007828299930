export const PlatformType = {
  FACEBOOK: 'facebook',
  LINE: 'line',
  INSTAGRAM: 'instagram',
  WHATSAPP: 'whatsapp',
  LIVECHAT: 'livechat',
};

export const GenderType = {
  MALE: 'male',
  FEMALE: 'female',
  CUSTOM: 'custom',
};

export const PhoneType = {
  EXISTS: 'exists',
  NOTEXISTS: 'not-exists',
};

export const EmailType = {
  EXISTS: 'exists',
  NOTEXISTS: 'not-exists',
};

export const FriendshipType = {
  EQ: 'eq_friendship',
  NE: 'ne_friendship',
};

export const ConversationDateType = {
  SEVEN_DAYS: '7',
  TWO_WEEKS: '14',
  THREE_WEEKS: '21',
  CUSTOM: 'custom',
};

export const JoinDateType = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  CUSTOM: 'custom',
};

export const FetchStatus = {
  INITIAL: 'initial',
  FETCHING: 'fetching',
  FIRST_DONE: 'first-done',
  DONE: 'done',
  FAILURE: 'failure',
  INITIAL_QUERY_FINISHED: 'INITIAL_QUERY_FINISHED',
};

export const CustomerFilterType = {
  JOIN_DATE: 'join-date',
  CONVERSATION_DATE: 'conversation-date',
  TAG: 'tag',
  PARTNER_TAG: 'partner-tag',
  PLATFORM: 'platform',
  GENDER: 'gender',
  PHONE: 'phone',
  EMAIL: 'email',
  FRIENDSHIP: 'friendship',
  NO_TAG: 'no-tag',
  INBOX: 'inbox',
};

export const TagFilterType = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
};

export const LanguageType = {
  ZH_TW: 'zh-TW',
  EN_US: 'en-US',
  JA_JP: 'ja-JP',
};

export const ModalType = {
  ADD_COUPON_MODAL: 'ADD_COUPON_MODAL',
  DISCONNECT_PLATFORM_MODAL: 'DISCONNECT_PLATFORM_MODAL',
  CONNECT_MESSENGER_MODAL: 'CONNECT_MESSENGER_MODAL',
  CONNECT_LINE_BIZ_TIP_MODAL: 'CONNECT_LINE_BIZ_TIP_MODAL',
  CONNECT_INSTAGRAM_MODAL: 'CONNECT_INSTAGRAM_MODAL',
  CONNECT_WHATSAPP_MODAL: 'CONNECT_WHATSAPP_MODAL',
  ADD_TAG_MODAL: 'ADD_TAG_MODAL',
  DELETE_TAG_MODAL: 'DELETE_TAG_MODAL',
  DELETE_TAGS_MODAL: 'DELETE_TAGS_MODAL',
  DELETE_NOTE_MODAL: 'DELETE_NOTE_MODAL',
  EDIT_NOTE_MODAL: 'EDIT_NOTE_MODAL',
  EXPORT_CUSTOMERS_MODAL: 'EXPORT_CUSTOMERS_MODAL',
  EXPORT_CHATPAY_ORDER_MODAL: 'EXPORT_CHATPAY_ORDER_MODAL',
  SYSTEM_TASK_MODAL: 'SYSTEM_TASK_MODAL',
  NOTIFICATION_CENTER_MODAL: 'NOTIFICATION_CENTER_MODAL',
  ALERT_MODAL: 'ALERT_MODAL',
  DIALOG_TEXT: 'DIALOG_TEXT',
  UPLOAD_DIALOG: 'UPLOAD_DIALOG',
  CHANGE_MAIL_MODAL: 'CHANGE_MAIL_MODAL',
  ORGANIZATION_ANNOUNCEMENT_MODAL: 'ORGANIZATION_ANNOUNCEMENT_MODAL',
  CONFIRM_DELETE_MODAL: 'CONFIRM_DELETE_MODAL',
  EC_ENABLE_MODAL: 'EC_ENABLE_MODAL',
};

export const SortType = {
  BY_COUNT_INCREAMENT: 'BY_COUNT_INCREAMENT',
  BY_COUNT_DECREAMENT: 'BY_COUNT_DECREAMENT',
  BY_CREATE_DATE_INCREAMENT: 'BY_CREATE_DATE_INCREAMENT',
  BY_CREATE_DATE_DECREAMENT: 'BY_CREATE_DATE_DECREAMENT',
  BY_DENSITY_DECREAMENT: 'BY_DENSITY_DECREAMENT',
  BY_DENSITY_INCREAMENT: 'BY_DENSITY_INCREAMENT',
};

export const StatusType = {
  done: 'done',
  INITIAL: 'INITIAL',
  initial: 'initial',
  INITIAL_QUERYING: 'INITIAL_QUERYING',
  INITIAL_QUERY_FINISHED: 'INITIAL_QUERY_FINISHED',
  INITIAL_QUERY_FAILURED: 'INITIAL_QUERY_FAILURED',
  QUERYING: 'QUERYING',
  QUERY_FINISHED: 'QUERY_FINISHED',
  QUERY_FAILURED: 'QUERY_FAILURED',
};

export const BatchManagementType = {
  INFOBIP_CONSOLE_SEND: 'infobip.console.send',
  ADD_TAGS: 'add-tags',
  DELETE_TAGS: 'del-tags',
  BROADCAST: 'broadcast',
  EXPORT_BROADCAST: 'export-broadcast',
  EXPORT_SHOP8_ORDER: 'export-shop8-orders',
  CREATE_CUSTOMER_GROUP: 'create-customer-group',
  UPDATE_CUSTOMER_GROUP: 'update-customer-group', // deprecated
  REFRESH_CUSTOMER_GROUP: 'refresh-customer-group',
  EXPORT: 'export',
  SUPERLINK_EXPORT: 'superlink.export',
  SHARE_RANK_EXPORT: 'growth_tools.export',
  CUSTOMER_GROUP_IMPORT_CSV: 'import-customer-group-csv',
  RICHMENU_PUBLISH: 'rich-menu.publish',
  RICHMENU_UNPUBLISH: 'rich-menu.unpublish',
  RICHMENU_DELETE: 'rich-menu.delete',
  RICHMENU_SCHEDULED: 'rich-menu.scheduled',
  COUPON_EXPORT: 'coupon-module.export',
  GAME_EXPORT: 'game-module.export',
  EINVOIVE_EXPORT: 'eInvoice.export',
  RETARGETING_EXPORT: 'retargeting.export',
  DATA_CENTER_MESSAGE_FROM_EXPORT: 'data-center-message-from-export',
  DATA_CENTER_TAG_STATISTIC_EXPORT: 'data-center-tag-statistic-export',
  DATA_CENTER_PLATFORM_STATISTIC_EXPORT:
    'data-center-platform-statistic-export',
  DATA_CENTER_TEMPLATE_ACCOUNT_EXPORT: 'export-templ-accounting',
  RECURRING_EXPORT: 'export-recurring-analytics',
  DELETE_CUSTOMER_GROUP: 'delete-customer-group',
  CS_DATA_EXPORT: 'csData.export',
  SHOP8_CSV: 'shop8-csv',
  POINT_HISTORY_EXPORT: 'point-history.export',
  EINOVICE_ITEM_EXPORT: 'eInvoice.items.export',
};

export const TaskStatusType = {
  STARTED: 'started',
  SCHEDULED: 'scheduled',
  PENDING: 'pending',
  PREPARING: 'preparing',
  WORKING: 'working',
  DISPATCHING: 'dispatching',
  DONE: 'done',
  ERROR: 'error',
  PROGRESS: 'progress',
  TERMINATED: 'terminated',
  PAUSING: 'pausing',
  DRAFT: 'draft',
};

export const SOCKETIO_EVENT_TYPE = {
  EVICT: 'evict',
  ASSIGNED: 'assigned',
  MESSAGE_NOTIFY: 'message/notify',
  INVITAION_ACCEPT: 'invitation/accept',
  SYSTEM_NOFITY: 'system/notify',
  TASK: 'task',
  INBOX: 'inbox',
  ANNOUNCEMENT: 'announcements',
  INTEGRATION: 'integration',
  SHOP8ORDER: 'shop8Order',
};

export const CustomerGroupStatusType = {
  UPDATING: 'updating',
  CREATING: 'creating',
  READY: 'ready',
  CUSTOM_IMPORT: 'import-custom-customer-group',
};

export const SnackBarType = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const FacebookTagType = {
  NO_TAG: 'NO_TAG',
  HUMAN_AGENT: 'HUMAN_AGENT',
  CONFIRMED_EVENT_UPDATE: 'CONFIRMED_EVENT_UPDATE',
  POST_PURCHASE_UPDATE: 'POST_PURCHASE_UPDATE',
  ACCOUNT_UPDATE: 'ACCOUNT_UPDATE',
};

export const WhatsappTagType = {
  NO_TAG: 'NO_TAG',
  WHATSAPP_TEMPLATE: 'WHATSAPP_TEMPLATE',
};

export const MessageTemplateTypes = {
  TEXT: 'text/plain',
  IMAGE: 'application/x-image',
  AUDIO: 'application/x-audio',
  VIDEO: 'application/x-video',
  FILE: 'application/x-file',
  WALIST: 'waList',
  RICHVIDEO_CARD: 'richvideo',
  OPTIONAL_CARD: 'card',
  CONFRIMED_CARD: 'confirm',
  BUTTON_CARD: 'button',
  IMAGE_CARD: 'image',
  LIST_CARD: 'list',
  IMAGEMAP_CARD: 'imagemap',
  RICHMESSAGE: 'application/x-imagemap',
};

export const VendorType = {
  RICH_MENU_EDITOR: {
    title: 'Line 圖文選單',
    type: 'rich_menu_editor',
  },
};

export const RichMenuGroupStatusType = {
  ON: 'on',
  OFF: 'off',
  LOADING: 'loading',
};

export const TaskEventType = {
  RECURRING_EXPORT: 'RECURRING_EXPORT',
  EXPORT_BROADCAST: 'EXPORT_BROADCAST',
  BROADCAST_SUCCESS: 'BROADCAST_SUCCESS',
  BATCH_MANAGEMENT_ADD_TAGS: 'BATCH_MANAGEMENT_ADD_TAGS',
  BATCH_MANAGEMENT_DELETE_TAGS: 'BATCH_MANAGEMENT_DELETE_TAGS',
  BATCH_CUSTOMER_GROUP_DELETE: 'BATCH_CUSTOMER_GROUP_DELETE',
  BATCH_MANAGEMENT_CUSTOMER_GROUP: 'BATCH_MANAGEMENT_CUSTOMER_GROUP',
  BATCH_MANAGEMENT_EXPORT: 'BATCH_MANAGEMENT_EXPORT',
  RICHMEMU_GROUP_PUBLISH: 'RICHMEMU_GROUP_PUBLISH',
  RICHMEMU_GROUP_UNPUBLISH: 'RICHMEMU_GROUP_UNPUBLISH',
  RICHMEMU_GROUP_DELETE: 'RICHMEMU_GROUP_DELETE',
  RICHMEMU_GROUP_SCHEDULED: 'RICHMEMU_GROUP_SCHEDULED',
  TASK_ERROR: 'TASK_ERROR',
  SUPERLINK_EXPORT: 'SUPERLINK_EXPORT',
  SHARE_RANK_EXPORT: 'SHARE_RANK_EXPORT',
  DATA_CENTER_MESSAGE_FROM_EXPORT: 'DATA_CENTER_MESSAGE_FROM_EXPORT',
  DATA_CENTER_TAG_STATISTIC_EXPORT: 'DATA_CENTER_TAG_STATISTIC_EXPORT',
  DATA_CENTER_PLATFORM_STATISTIC_EXPORT:
    'DATA_CENTER_PLATFORM_STATISTIC_EXPORT',
  INFOBIP_CONSOLE_SEND: 'INFOBIP_CONSOLE_SEND',
  EXPORT_GAME_MODULE: 'EXPORT_GAME_MODULE',
  DATA_CENTER_TEMPLATE_ACCOUNT_EXPORT: 'DATA_CENTER_TEMPLATE_ACCOUNT_EXPORT',
  EXPORT_COUPON_MODULE: 'EXPORT_COUPON_MODULE',
  EINVOIVE_EXPORT: 'EINVOIVE_EXPORT',
  RETARGETING_EXPORT: 'RETARGETING_EXPORT',
  CUSTOMER_GROUP_IMPORT_CSV: 'CUSTOMER_GROUP_IMPORT_CSV',
  CS_DATA_EXPORT: 'CS_DATA_EXPORT',
  SHOP8_CSV: 'SHOP8_CSV',
  POINT_HISTORY_EXPORT: 'POINT_HISTORY_EXPORT',
  EINOVICE_ITEM_EXPORT: 'EINOVICE_ITEM_EXPORT',
};

export const UploadFileType = {
  PNG: 'image/png',
  JPG: 'image/jpg',
};

export const ImagemapTemplateType = {
  ImagemapTemplate1: [
    {
      bounds: {
        x: 0,
        y: 0,
        width: 320,
        height: 320,
      },
      action: { type: 'postback', tags: [], displayText: '' },
    },
  ],
  ImagemapTemplate2: [
    {
      bounds: {
        x: 0,
        y: 0,
        width: 320,
        height: 160,
      },
      action: { type: 'postback', tags: [], displayText: '' },
    },
    {
      bounds: {
        x: 0,
        y: 160,
        width: 320,
        height: 160,
      },
      action: { type: 'postback', tags: [], displayText: '' },
    },
  ],
  ImagemapTemplate3: [
    {
      bounds: {
        x: 0,
        y: 0,
        width: 320,
        height: 160,
      },
      action: { type: 'postback', tags: [], displayText: '' },
    },
    {
      bounds: {
        x: 0,
        y: 160,
        width: 160,
        height: 160,
      },
      action: { type: 'postback', tags: [], displayText: '' },
    },
    {
      bounds: {
        x: 160,
        y: 160,
        width: 160,
        height: 160,
      },
      action: { type: 'postback', tags: [], displayText: '' },
    },
  ],
  ImagemapTemplate4: [
    {
      bounds: {
        x: 0,
        y: 0,
        width: 160,
        height: 160,
      },
      action: { type: 'postback', tags: [], displayText: '' },
    },
    {
      bounds: {
        x: 160,
        y: 0,
        width: 160,
        height: 160,
      },
      action: { type: 'postback', tags: [], displayText: '' },
    },
    {
      bounds: {
        x: 0,
        y: 160,
        width: 160,
        height: 160,
      },
      action: { type: 'postback', tags: [], displayText: '' },
    },
    {
      bounds: {
        x: 160,
        y: 160,
        width: 160,
        height: 160,
      },
      action: { type: 'postback', tags: [], displayText: '' },
    },
  ],
  ImagemapTemplate5: [
    {
      bounds: {
        x: 0,
        y: 0,
        width: 160,
        height: 160,
      },
      action: { type: 'postback', tags: [], displayText: '' },
    },
  ],
};

export const FollowType = {
  FOLLOW: 'follow',
  UNFOLLOW: 'unfollow',
};

export const MetricsClientName = {
  MESSAGE_INCREMENT: 'message_increment', // 訊息增長量
  FACEBOOK_JOIN_INCREMENT: 'facebook_join_increment', // FB顧客成長數
  LINE_JOIN_INCREMENT: 'line_join_increment', // LINE顧客成長數
  TAG_INCREMENT: 'tag_increment', // 標籤數量增長數
  BROADCAST_LINE_BLOCK_NUMBER: 'broadcast_line_block_number', // 群發 LINE 封鎖數
  BROADCAST_MESSAGE_TOTAL: 'broadcast_message_total', // 群發總訊息量
  BROADCAST_LINE_UNIQUE_RATIO: 'broadcast_line_unique_ratio', // LINE不重複群發比例
  BROADCAST_OPEN_CLICK_RATE: 'broadcast_open_click_rate', // 群發開封點擊率
  BROADCAST_REPEAT_CLICK_RATE: 'broadcast_repeat_click_rate', // 群發重複點擊率
  GROWTH_TOOL_NEW_JOIN_CONVERSION_RATE: 'growth_tool_new_join_conversion_rate', // 加入好友轉換率
  GROWTH_TOOL_SHARE_LINK_CLICK_NUMBER: 'growth_tool_share_link_click_number', // 分享連結被點擊數
  GROWTH_TOOL_CAMPAIGN_DIFFUSION_RATE: 'growth_tool_campaign_diffusion_rate', // 活動擴散率
  GROWTH_TOOL_NEW_CUSTOMER_NUMBER: 'growth_tool_new_customer_number', // 點擊連結優惠發送次數
  GROWTH_TOOL_SHARER_REWARD: 'growth_tool_sharer_reward', // 總加入新顧客數
  SUPERLINK_CHANNEL_1_CONVERSION_RATE: 'superlink_channel_1_conversion_rate', // super link 1
  SUPERLINK_CHANNEL_2_CONVERSION_RATE: 'superlink_channel_2_conversion_rate', // super link 2
  SUPERLINK_CHANNEL_3_CONVERSION_RATE: 'superlink_channel_3_conversion_rate', // super link 3
  SUPERLINK_CHANNEL_4_CONVERSION_RATE: 'superlink_channel_4_conversion_rate', // super link 4
  SUPERLINK_CHANNEL_5_CONVERSION_RATE: 'superlink_channel_5_conversion_rate', // super link 5
  CUSTOMER_INTERACTION_SUPER_FANS_NUMBER:
    'customer_interaction_super_fans_number', // 超級鐵粉數
  CUSTOMER_INTERACTION_NEW_JOIN_NUMBER: 'customer_interaction_new_join_number', // 新客戶數
  CUSTOMER_INTERACTION_ACTIVE_NUMBER: 'customer_interaction_active_number', // 最後對話在半年內人數
  CUSTOMER_INTERACTION_LOST_NUMBER: 'customer_interaction_lost_number', // 最後對話在半年至一年人數
  CUSTOMER_INTERACTION_DEAD_NUMBER: 'customer_interaction_dead_number', // 最後對話超過一年人數
  BRODCAST_MESSAGE_TOTAL: 'brodcast_message_total', // 群發總訊息量
  BRODCAST_REACH_PEOPLE_NUMBER: 'brodcast_reach_people_number', // 訊息觸及人數
  BRODCAST_OPEN_CLICK_RATE: 'brodcast_open_click_rate', // 群發開封點擊率
  BRODCAST_REPEAT_CLICK_RATE: 'brodcast_repeat_click_rate', // 群發重複點擊率
  GROWTH_TOOL_NEW_SHARER_NUMBER: 'growth_tool_new_sharer_number', // 點擊連結優惠發送次數
  GROWTH_TOOL_PARTICIPANT_REWARD: 'growth_tool_participant_reward', // 總加入新顧客數
  FACEBOOK_JOIN_TOTAL: 'facebook_join_total', // FB 顧客總數
  FACEBOOK_BLOCK_NUMBER: 'facebook_block_number', // FB 封鎖數
  MESSAGE_FACEBOOK_TOTAL: 'message_facebook_total', // FB 訊息總量
  MESSAGE_FACEBOOK_INCREMENT: 'message_facebook_increment', // FB 訊息增長量
  LINE_JOIN_TOTAL: 'line_join_total', // LINE 顧客總數
  LINE_BLOCK_NUMBER: 'line_block_number', // LINE 封鎖數
  MESSAGE_LINE_TOTAL: 'message_line_total', // LINE 訊息總量
  MESSAGE_LINE_INCREMENT: 'message_line_increment', // LINE 訊息增長量
  INSTAGRAM_JOIN_TOTAL: 'instagram_join_total', // IG 顧客總數
  MESSAGE_INSTAGRAM_TOTAL: 'message_instagram_total', // IG 訊息總量
  MESSAGE_INSTAGRAM_INCREMENT: 'message_instagram_increment', // IG 訊息增長量

  LIVECHAT_JOIN_TOTAL: 'livechat_join_total',
  MESSAGE_LIVECHAT_TOTAL: 'message_livechat_total',
  MESSAGE_LIVECHAT_INCREMENT: 'message_livechat_increment',

  WHATSAPP_JOIN_TOTAL: 'whatsapp_join_total',
  MESSAGE_WHATSAPP_TOTAL: 'message_whatsapp_total',
  MESSAGE_WHATSAPP_INCREMENT: 'message_whatsapp_increment',
};

export const MetricsSource = {
  DAILAY: 'daily',
  LAMBDA: 'lambda',
};

export const MetricsPeriod = {
  NONE: 'none',
  YESTERDAY: 'yesterday',
  DAY: 'day',
  START_DAY: 'start_day',
  END_DAY: 'end_day',
  LASTWEEK: 'lastWeek',
  WEEK: 'week',
  LASTMONTH: 'lastMonth',
  MONTH: 'month',
  QUARTER: 'quarter',
  HALF_YEAR: 'half_year',
  YEAR: 'year',
  CUSTOM: 'custom',
};

export const MetrucsUnit = {
  NONE: '',
  PERCENTAGE: '%',
};

export const MessageAnalysisClientName = {
  MESSAGE_FROM_CUSTOMER_NUMBER: 'message_from_customer_number', // 顧客進線
  MESSAGE_FROM_BROADCAST_NUMBER: 'message_from_broadcast_number', // 群發訊息
  MESSAGE_FROM_USER_NUMBER: 'message_from_user_number', // 客服發出
  MESSAGE_FROM_BOT_NUMBER: 'message_from_bot_number', // 機器人發出
  MESSAGE_FROM_FOREIGN_NUMBER: 'message_from_foreign_number', // 外部訊息
};

export const StatisticType = {
  DELTA: 'delta',
  SUM: 'sum',
};

export const MessageContentType = {
  TYPE_IMAGE: 'application/x-image',
  TYPE_AUDIO: 'application/x-audio',
  TYPE_VIDEO: 'application/x-video',
  TYPE_FILE: 'application/x-file',
  TYPE_IMAGEMAP: 'application/x-imagemap',
  TYPE_LOCATION: 'application/x-location',
  TYPE_TEMPLATE: 'application/x-template',
  TYPE_LINE_STICKER: 'application/x-line-sticker',
  TYPE_TEXT_PLAIN: 'text/plain',
  TYPE_SHARE: 'application/x-share',
  TYPE_SYSTEM_READ: 'application/x-read',
  TYPE_TYPING: 'application/x-typing',
  TYPE_DATE_LABEL: 'application/x-date-label',
  TYPE_LASTREAD_LABEL: 'application/x-lastread-label',
  TYPE_NOTIFY_EVENT: 'application/x-notify-event',
  TYPE_NOTIFY_ERROR: 'application/x-notify-error',
  NOTIFY_EVENT_CUSTOMER_LOGIN: 'event/ec-customer-logined',
  NOTIFY_EVENT_ACCEPT: 'event/accept',
  NOTIFY_EVENT_JOIN: 'event/join',
  NOTIFY_EVENT_LEFT: 'event/left',
  NOTIFY_EVENT_CUSTOMER_FOLLOW: 'event/customer-follow',
  NOTIFY_EVENT_CUSTOMER_BLOCK: 'event/customer-block',
  NOTIFY_EVENT_REFERRAL: 'event/customer-referral',
  SOURCE_REFERRAL_SHORTLINK: 'SHORTLINK',
  SOURCE_REFERRAL_ADS: 'ADS',
  SOURCE_REFERRAL_MESSENGER_CODE: 'MESSENGER_CODE',
  SOURCE_REFERRAL_DISCOVER_TAB: 'DISCOVER_TAB',
  SOURCE_REFERRAL_CUSTOMER_CHAT_PLUGIN: 'CUSTOMER_CHAT_PLUGIN',
  NOTIFY_EVENT_LOCKMSG_END: 'event/lockedMsg-end',
  NOTIFY_EVENT_LOCKMSG_START: 'event/lockedMsg-start',
  X_REACT: 'application/x-react',
  X_UNREACT: 'application/x-unreact',
  DELETE: 'application/x-delete',
  FORM: 'application/x-form',
  STORY_MENTION: 'application/x-story_mention',
  LIKE_HEART: 'application/x-like-heart',
  CUSTOMER_SHARE: 'application/x-customer-share',
  UNSUPPORTED: 'application/x-unsupported',
  X_CUSTOMER_FORM: 'application/x-customer-form',
  X_RECURRING_NOTIFICATIONS: 'application/x-recurring-notifications',
  START_NOTIFICATIONS: 'event/start-notifications',
  STOP_NOTIFICATIONS: 'event/stop-notifications',
};

export const AuthorityType = {
  GENERAL: 'general',
  PRIVATE_ONLY: 'private-only',
  CUSTOMER_READ_ONLY: 'customer-readonly',
  ADMIN: 'admin',
};

export const behaviorAvailableType = {
  JOINEDAT: 'joinedAt',
  // LASTMESSAGEAT: 'lastMessageAt',
  LASTINBOUNDAT: 'lastInboundAt',
};

export const sortType = {
  ASC: 'asc',
  DESC: 'desc',
};

export const AwardType = {
  CODE: 'promoCode',
  LINE_POINTER: 'linePoints',
  PHYSICAL_AWARD: 'prize',
  NOT_WIN: 'miss',
};

export const CouponType = {
  CODE: 'promoCode',
  LINE_POINTER: 'linePoints',
  PHYSICAL_AWARD: 'prize',
  LINK: 'link',
  OTHER: 'other',
};

export const PlanType = {
  FREE: 'free',
  BASIC: 'basic',
  BUSINESS: 'business',
  ENTERPRISE: 'enterprise',
};

export const VendorStore = {
  BOT_KEYWORD: 'keyword',
  BOT_OFFLINE: 'offline',
  BOT_WELCOME: 'welcome',
  BOT_EXCUTOR: 'bot_excutor',
  BOT_IMAGE: 'bot_image_render',
  BOT_EDITOR: 'bot_editor',
  SUPER_LINK_VENDOR_ID: 'superlink',
  GROWTH_TOOL_VENDOR_ID: 'growth_tool',
  ROULETTE_VENDOR_ID: 'game_module_roulette',
  MA_VENDOR_ID: 'marketing_automation',
  SCRATCH_VENDOR_ID: 'game_module_scratch',
  SLOTMACHINE_VENDOR_ID: 'game_module_slot',
  COUPON_VENDOR_ID: 'coupon_module',
  RICH_MENU_VENDOR_ID: 'rich_menu_editor',
  PNP_VENDOR_ID: 'infobip',
  SUPER8: 'super8',
  EINVOICE_VENDOR_ID: 'eInvoice',
};

export const TriggerType = {
  ADD: 'join',
  UNBLOCK: 'rejoin',
  TAG: 'tag',
  KEYWORD: 'keyword',
  BOT: 'bot',
  SUPERLINK: 'superlink',
  PROCEDURE: 'ma',
  API: 'api',
};

export const NodeType = {
  TRIGGER: 'trigger',
  MESSAGE: 'message',
  START: 'start',
  END: 'end',
  CONDITION: 'condition',
  MA: 'ma',
  GROUP: 'group',
  TAGGING: 'tagging',
  GACONDITION: 'GA_condition',
};

export const MAStatusType = {
  SCHEDULED: 'scheduled',
  PROGRESS: 'progress',
  PAUSING: 'pausing',
  TERMINATED: 'terminated',
  DONE: 'done',
  EDIT: 'editing',
  ERROR: 'error',
};

export const MACustomerStatusType = {
  PROGRESS: 'progress',
  DONE: 'done',
  UNFINISH: 'unfinish',
  ERROR: 'error',
};

export const TriggerTimeType = {
  DAY: 'day',
  HOUR: 'hour',
};

export const RecurringMessageFrequencyType = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const RecurringMessageStatus = {
  DRAFT: 'draft',
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

export const SuperMarketType = {
  GAME_MODULE_ROULETTE: 'game_module_roulette',
  GAME_MODULE_SCRATCH: 'game_module_scratch',
  GAME_MODULE_SLOT: 'game_module_slot',
  COUPON_MODULE: 'coupon_module',
  GROWTH_TOOL: 'growth_tool',
  E_INVOICE: 'eInvoice',
  LINE_ROLE_SETTING: 'lineRoleSetting',
  OFFLINE_SETTING: 'offlineSetting',
  SUPER8API: 'super8api',
  LINE_OFFICIAL_NOTIFICATION: 'lineOfficialNotification',
  SHOPLINE: 'shopline',
  MITAKE: 'mitake',
  SHOPLINE_RETARGETING: 'shoplineRetargeting',
  RETARGETING: 'retargeting',
  RECURRING_MESSAGE: 'recurringMessage',
  SURVEY_CAKE: 'surveyCake',
  SUPER8_AI: 'super8AI',
  GA4_EVENT: 'ga4Event',
  SELFBUILDOFFICIAL: 'selfBuildOfficial',
  GSS_VITAL: 'gssVital',
  SHOPIFY: 'shopify',
  CYBERBIZ: 'cyberbiz',
  BOT_91App: '91_app',
};

export const CustomerServiceDataCaseType = {
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
};

export const CustomerServiceDataServiceAvgEffectType = {
  AVGWAITINGTIME: 'avgWaitTime',
  AVGPROCESSTIME: 'avgProcessTime',
  AVGDONERATE: 'avgDoneRate',
  AVGMESSAGESENT: 'avgMessageSent',
};

export const CustomerServiceDataServiceEffectType = {
  WAITTIME: 'waitTime',
  PROCESSTIME: 'processTime',
  DONERATE: 'doneRate',
  MESSAGESENT: 'messageSent',
};

export const PaymentStatus = {
  UNPAID: 'unpaid', // 未付款
  PAID: 'paid', // 已付款
  REFUNDING: 'refunding', // 退款中
  REFUND: 'refund', // 已退款
  PARTIALREFUND: 'partial_refund', // 已部分退款
  CANCELLED: 'cancelled',
};

export const ShipStatus = {
  PROCESSING: 'processing', // 備貨中
  SHIPPING: 'shipping', // 發貨中
  SHIPPED: 'shipped', // 已發貨
  DELIVERED: 'delivered', // 已到達
  PICKED: 'picked', // 已取貨
  RETURNING: 'returning', // 退貨中
  RETURNED: 'returned', // 已退貨
  CANCELLED: 'cancelled',
};

export const ChatPayOrderStatus = {
  PROCESSING: 'processing',
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
};

export const ChatPayProductStatus = {
  DRAFT: 'draft',
  PUBLISH: 'publish',
};

export const GATriggerType = {
  GAVIEW: 'GA_pageView',
  GAREMOVEFROMCART: 'GA_removeFromCart',
  GAADDTOCART: 'GA_addToCart',
  GAPURCHASE: 'GA_purchase',
};

export const PartnerTagVendor = {
  GSSVITAL: 'gssVital',
  OMNISEGMENT: 'omniSegment',
};

export const ChatPayPaymentType = {
  ECPAY: 'ecpay',
  SELF: 'self',
};

export const CaseStatusType = {
  PROCESSING: 'processing',
  ASSIGN: 'assign',
  DONE: 'done',
};

export const UberDirectDeliveryStatus = {
  UNPAID: 'unpaid',
  ORDER_EXPIRY_REMINDER: 'orderExpiryReminder',
  PROCESSING: 'processing',
  CONFIRMED: 'confirmed',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

export const UberDirectDeliverableAction = {
  DELIVERABLE_ACTION_MEET_AT_DOOR: 'deliverable_action_meet_at_door',
  DELIVERABLE_ACTION_LEAVE_AT_DOOR: 'deliverable_action_leave_at_door',
};

export const ChatPayShippingMethod = {
  SELF: 'self',
  UBER_DIRECT: 'uberDirect',
};

export const UberDeliveryStatus = {
  PENDING: 'pending',
  PICKUP: 'pickup',
  PICKUP_COMPLETE: 'pickup_complete',
  DROPOFF: 'dropoff',
  DELIVERED: 'delivered',
  CANCELED: 'canceled',
  RETURNED: 'returned',
};

export const LonUsageWaitSec = {
  ONE: 3600,
  TWO: 7200,
  THREE: 10800,
};
