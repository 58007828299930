import { combineReducers } from 'redux';
import authReducer from './authReducer';
import organizationReducer from './organizationReducer';
import customerReducer from './customerReducer';
import actionType from '../actions/type';
import tagReducer from './tagReducer';
import fileReducer from './fileReducer';
import preferenceReducer from './preferenceReducer';
import config from '../config';
import templateReducer from './templateReducer';
import metaCrawlReducer from './metaCrawlReducer';
import broadcastReducer from './broadcastReducer';
import growthToolReducer from './growthToolReducer';
import super8apiReducer from './super8apiReducer';
import modalReducer from './modalReducer';
import allCustomersReducer from './allCustomersReducer';
import tagManagementReducer from './tagManagementReducer';
import superlinkReducer from './superlinkReducer';
import notificationCenterReducer from './notificationCenterReducer';
import systemTaskReducer from './systemTaskReducer';
import socketioReducer from './socketioReducer';
import customerGroupsReducer from './customerGroupsReducer';
import snackbarReducer from './snackbarReducer';
import richMenuSettingReducer from './richMenuSettingReducer';
import overviewReducer from './overviewReducer';
import memberManagementReducer from './memberManagementReducer';
import trustRouteReducer from './trustRouteReducer';
import webviewEditorReducer from './webviewEditorReducer';
import rouletteReducer from './rouletteReducer';
import couponReducer from './couponReducer';
import scratchReducer from './scratchReducer';
import marketingAutomationReducer from './marketingAutomationReducer';
import slotMachineReducer from './slotMachineReducer';
import superMarketReducer from './superMarketReducer';
import eInvoiceReducer from './eInvoiceReducer';
import livechatReducer from './livechatReducer';
import retargetingReducer from './retargetingReducer';
import recurringMessageReducer from './recurringMessageReducer';
import surveyCakeReducer from './surveyCakeReducer';
import customerServiceDataReducer from './customerServiceDataReducer';
import chatPayReducer from './chatPayReducer';

const isSidebarExpand =
  localStorage.getItem(config.IS_SIDEBAR_EXPAND_NAME) === null
    ? true
    : localStorage.getItem(config.IS_SIDEBAR_EXPAND_NAME) === 'true';
const toggleFilterReducer = (isOpen = false, action) => {
  if (action.type === actionType.TOGGLE_FILTER_PANEL) {
    return action.payload;
  }
  return isOpen;
};
const messageInfoReducer = (data = '', action) => {
  if (action.type === actionType.SWITCH_INFO_PANEL) {
    return action.payload;
  }
  return data;
};
const toggleNavBarReducer = (data = isSidebarExpand, action) => {
  switch (action.type) {
    case actionType.TOGGLE_NAVBAR:
      return !data;
    default:
      return data;
  }
};

export default combineReducers({
  chatPay: chatPayReducer,
  customerServiceData: customerServiceDataReducer,
  surveyCake: surveyCakeReducer,
  recurringMessage: recurringMessageReducer,
  retargeting: retargetingReducer,
  livechat: livechatReducer,
  eInvoice: eInvoiceReducer,
  ma: marketingAutomationReducer,
  slotMachine: slotMachineReducer,
  coupon: couponReducer,
  scratch: scratchReducer,
  roulette: rouletteReducer,
  webviewEditor: webviewEditorReducer,
  trustRoute: trustRouteReducer,
  overview: overviewReducer,
  auth: authReducer,
  organization: organizationReducer,
  customer: customerReducer,
  navBarOpen: toggleNavBarReducer,
  toggleFilter: toggleFilterReducer,
  tags: tagReducer,
  infoPanel: messageInfoReducer,
  files: fileReducer,
  preference: preferenceReducer,
  template: templateReducer,
  metaCrawlData: metaCrawlReducer,
  broadcast: broadcastReducer,
  growthTool: growthToolReducer,
  super8api: super8apiReducer,
  modal: modalReducer,
  allCustomers: allCustomersReducer,
  tagManagement: tagManagementReducer,
  superlink: superlinkReducer,
  notificationCenter: notificationCenterReducer,
  systemTask: systemTaskReducer,
  socketio: socketioReducer,
  customerGroups: customerGroupsReducer,
  snackbar: snackbarReducer,
  richMenuSetting: richMenuSettingReducer,
  memberManagement: memberManagementReducer,
  superMarket: superMarketReducer,
});
