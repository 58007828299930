import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import hexRgba from 'hex-rgba';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ImageDisplay from './ImageDisplay';
import Loading from './Loading';

import { color } from '../../themes';
import { selectOrganization } from '../../actions';
import { closeModal } from '../../actions/modalAction';

import AddOrganizationListItem from '../navigation/AddOrganizationListItem';
import icNavArrowDown from '../../assets/images/svg/ic-nav-arrow-down.svg';
import icNavArrowUp from '../../assets/images/svg/ic-nav-arrow-up.svg';

const useStyles = makeStyles(
  theme => ({
    loading: {
      marginTop: 24,
    },
    groupDropdown: {
      backgroundColor: color.$6,
      width: 256,
      position: 'relative',
      zIndex: 999,
      WebkitUserSelect: 'none',
    },
    itemShow: {
      display: 'grid',
      gridTemplateColumns: '48px 1fr',
      gridTemplateRows: '48px 20px',
      padding: '12px 16px 10px',
      cursor: 'pointer',
    },
    listitem: {
      padding: '0',
      height: '56px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: hexRgba(color.$7, 10),
      },
    },
    showDropdownMenu: {
      height: 'calc(100vh - 136px)',
      backgroundColor: color.$6,
      position: 'absolute',
      width: '100%',
      top: '100%',
      overflowY: 'auto',
      padding: '0 0 8px',
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hideDropdownMenu: {
      backgroundColor: color.$6,
      position: 'absolute',
      width: '100%',
      top: '100%',
      height: '0vh',
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    display3: {
      fontStyle: 'normal',
      letterSpacing: '0px',
      color: color.$3,
      fontSize: 17,
      fontWeight: '400',
    },
    orgIconBlock: {
      marginLeft: 16,
    },
    expandIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gridColumnStart: 1,
      gridColumnEnd: 2,
      gridRowStart: 2,
      gridRowEnd: 3,
    },
  }),
  {
    name: 'OrganizationList',
  },
);

const OrganizationList = ({ textStyle }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [displayMenu, setDisplayMenu] = useState(false);

  const selectOrg = useSelector(store => store.organization.selectOrg);
  const orgData = useSelector(store => store.organization.orgData);
  const isFetchLoading = useSelector(
    store => store.organization.isFetchLoading,
  );
  const isOpen = useSelector(store => store.modal.isOpen);

  const handleListItemClick = (prevOrgId, objectId) => {
    if (isOpen) {
      dispatch(closeModal());
    }

    dispatch(selectOrganization(orgData, objectId));
    setDisplayMenu(false);
    if (pathname === `/overview/${prevOrgId}/all`) {
      navigate(`/overview/${objectId}`);
    } else {
      navigate(`/overview/${objectId}/all`);
    }
  };

  const toggleDropdownMenu = async e => {
    e.preventDefault();
    setDisplayMenu(!displayMenu);
  };

  if (isFetchLoading) {
    return (
      <div className={classes.loading}>
        <Loading />
      </div>
    );
  }
  if (!selectOrg) {
    return (
      <div className={classes.groupDropdown}>
        <AddOrganizationListItem
          show={false}
          displayMenu={displayMenu}
          textStyle={textStyle}
        />
      </div>
    );
  }

  return (
    <div className={classes.groupDropdown}>
      <ListItem onClick={toggleDropdownMenu} className={classes.itemShow}>
        <ImageDisplay
          style={{
            display: 'block',
            borderRadius: '50%',
            gridColumnStart: 1,
            gridColumnEnd: 2,
            gridRowStart: 1,
            gridRowEnd: 2,
          }}
          src={selectOrg.icon}
          width={48}
          height={48}
        />
        <div
          style={{
            width: '132px',
            gridColumnStart: 2,
            gridColumnEnd: 3,
            gridRowStart: 1,
            gridRowEnd: 2,
          }}
        >
          <Typography className={`${classes.display3} ${textStyle}`}>
            {selectOrg.displayName}
          </Typography>
        </div>
        <div className={classes.expandIcon}>
          <img
            alt="chevron"
            src={displayMenu ? icNavArrowUp : icNavArrowDown}
            width={8}
            height={4}
          />
        </div>
      </ListItem>
      <div
        className={
          displayMenu ? classes.showDropdownMenu : classes.hideDropdownMenu
        }
      >
        {displayMenu && (
          <>
            {orgData
              .filter(orgArray => orgArray.objectId !== selectOrg.objectId)
              .map(item => (
                <ListItem
                  key={item.objectId}
                  className={classes.listitem}
                  onClick={() =>
                    handleListItemClick(selectOrg.objectId, item.objectId)
                  }
                >
                  <ImageDisplay
                    style={{ borderRadius: '50%', marginLeft: '22px' }}
                    src={item.icon}
                    width={36}
                    height={36}
                    badgeColor="primary"
                  />
                  <div style={{ width: '166px' }}>
                    <Typography variant="subtitle1" className={textStyle}>
                      {item.displayName}
                    </Typography>
                  </div>
                </ListItem>
              ))}
            <AddOrganizationListItem
              show
              displayMenu={displayMenu}
              textStyle={textStyle}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default OrganizationList;
