import { useState } from 'react';
import { color } from '../../../themes';

const IconImage = ({ onClick, className, id, iconColor }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={onClick}
    >
      <defs>
        <path
          id="image"
          d="M17.6 4H6.4C5.04 4 4 5.04 4 6.4v11.2C4 18.96 5.04 20 6.4 20h11.2c1.36 0 2.4-1.04 2.4-2.4V6.4C20 5.04 18.96 4 17.6 4zm-12 2.4c0-.48.32-.8.8-.8h11.2c.48 0 .8.32.8.8v6.08l-2.64-2.64a.773.773 0 0 0-1.12 0l-8.48 8.48a.768.768 0 0 1-.56-.72V6.4zm12 12H8.32l6.88-6.88 3.2 3.2v2.88c0 .48-.32.8-.8.8zm-8.4-7.2c1.12 0 2-.88 2-2s-.88-2-2-2-2 .88-2 2 .88 2 2 2zm0-2.4c.24 0 .4.16.4.4 0 .24-.16.4-.4.4-.24 0-.4-.16-.4-.4 0-.24.16-.4.4-.4z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={`imageMask${id}`} fill="#fff">
          <use xlinkHref="#image" />
        </mask>
        <g fill={isHover ? color.$1 : iconColor} mask={`url(#imageMask${id})`}>
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
};

IconImage.defaultProps = {
  iconColor: color.$12,
};

export default IconImage;
