import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Editor, EditorState, ContentState, Modifier } from 'draft-js';
import classnames from 'classnames';
import EmojiPicker from '../EmojiPicker';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import TextDecorator from './TextDecorator';
import { HASHTAG, HASHTAG_REGEX } from '../../../common/utils';

import { color } from '../../../themes';

const useStyles = makeStyles(
  () => ({
    textContainer: {
      position: 'relative',
    },
    content: {
      marginTop: '12px',
      width: '100%',
      minHeight: 124,
      fontSize: 15,
      color: color.$3,
      borderRadius: 24,
      border: 'solid 1px #bdbdbd',
      backgroundColor: '#ffffff',
      padding: '16px 24px',
      margin: '12px 0 0 0',
      resize: 'none',
      outline: 'none',
      paddingBottom: 32,
    },
    tootip: {
      top: '12px',
    },
    endAdornment: {
      position: 'absolute',
      right: 0,
      bottom: -5,
      display: 'flex',
      '& > span, & > div > span': {
        width: 44,
        height: 44,
        display: 'inline-block',
      },
    },
    maxLength: {
      position: 'absolute',
      right: 12,
      top: 18,
      fontSize: '11px',
      color: color.$10,
    },
    addName: {
      position: 'absolute',
      left: 24,
      bottom: 14,
      display: 'inline-flex',
      alignItems: 'center',
      color: color.$2,
      fontSize: 15,
      lineHeight: 1,
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.6',
      },
      '& > span': {
        display: 'flex',
        alignItems: 'center',
      },
      '& > span > span': {
        marginRight: 4,
        width: 20,
        height: 20,
      },
    },
  }),
  {
    name: 'TextEditor',
  },
);

const TextEditor = ({
  onChange,
  origriData,
  placeholder,
  maxLength,
  addTooltip,
  isShowCustomerName,
  isShowEmoji,
  isDialog,
  disabled,
  style,
  className,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(TextDecorator),
  );

  const editor = useRef(null);

  const focusEditor = () => {
    editor.current.focus();
  };

  const getSearchTextAt = (blockText, position) => {
    const str = blockText.substr(0, position);

    const insert = str.length;

    return {
      begin: insert,
      end: insert,
    };
  };

  const getSearchText = selection => {
    const anchorKey = selection.getAnchorKey();
    const anchorOffset = selection.getAnchorOffset();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const blockText = currentBlock.getText();
    return getSearchTextAt(blockText, anchorOffset);
  };

  const handleChange = teditorState => {
    setEditorState(teditorState);

    const value = teditorState.getCurrentContent().getPlainText();

    onChange(value);
  };

  const insertCharacter = () => {
    const contentStateWithEntity = editorState
      .getCurrentContent()
      .createEntity('mention', 'SEGMENTED');
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const currentSelectionState = editorState.getSelection();

    const { begin, end } = getSearchText(currentSelectionState);
    const mentionTextSelection = currentSelectionState.merge({
      anchorOffset: begin,
      focusOffset: end,
    });

    let mentionReplacedContent = Modifier.replaceText(
      editorState.getCurrentContent(),
      mentionTextSelection,
      HASHTAG,
      undefined, // no inline style needed
      entityKey,
    );

    if (begin === 0) {
      mentionReplacedContent = Modifier.replaceText(
        editorState.getCurrentContent(),
        mentionTextSelection,
        ` ${HASHTAG}`,
        undefined, // no inline style needed
        entityKey,
      );
    }
    // If the mention is inserted at the end, a space is appended right after for
    // a smooth writing experience.
    const blockKey = mentionTextSelection.getAnchorKey();
    const blockSize = editorState
      .getCurrentContent()
      .getBlockForKey(blockKey)
      .getLength();
    if (blockSize === end) {
      mentionReplacedContent = Modifier.insertText(
        mentionReplacedContent,
        mentionReplacedContent.getSelectionAfter(),
        ' ',
      );
    }

    const newEditorState = EditorState.push(
      editorState,
      mentionReplacedContent,
      'insert-fragment',
    );

    handleChange(
      EditorState.forceSelection(
        newEditorState,
        mentionReplacedContent.getSelectionAfter(),
      ),
    );
  };

  const handlePastedText = text => {
    const newState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text.trim(),
    );
    handleChange(EditorState.push(editorState, newState, 'insert-fragment'));

    return true;
  };

  const onEmojiClick = item => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      item,
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters',
    );

    handleChange(newEditorState);
  };

  const getEditorLength = () => {
    return editorState
      .getCurrentContent()
      .getPlainText()
      .replace(HASHTAG_REGEX, t('broadcast:customerName')).length;
  };

  const getStyles = () => {
    const res = {};
    if (maxLength) {
      res.paddingRight = 58;
      if (getEditorLength() > maxLength) {
        res.border = `1px solid ${color.$17}`;
      }
    }
    return res;
  };

  useEffect(() => {
    const value = editorState.getCurrentContent().getPlainText();

    if (origriData !== value) {
      handleChange(
        EditorState.createWithContent(
          ContentState.createFromText(origriData),
          TextDecorator,
        ),
      );
    }
  }, [origriData]);

  return (
    <div className={classnames(classes.textContainer, className)} style={style}>
      <div
        className={classes.content}
        style={getStyles()}
        onClick={focusEditor}
      >
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={e => handleChange(e)}
          placeholder={placeholder}
          handlePastedText={e => handlePastedText(e)}
          readOnly={disabled}
        />
      </div>
      {maxLength > 0 && (
        <div
          className={classes.maxLength}
        >{`${getEditorLength()}/${maxLength}`}</div>
      )}
      {!disabled && isShowCustomerName && (
        <div
          className={classes.addName}
          onClick={() => {
            insertCharacter();
          }}
        >
          <Tooltip placement="top" title={addTooltip}>
            <span>
              <Icon id="name" iconColor={color.$2} type="name" />
              {t('broadcast:addOriginName')}
            </span>
          </Tooltip>
        </div>
      )}
      {!disabled && isShowEmoji && (
        <div className={classes.endAdornment}>
          <EmojiPicker
            style={{
              top: 'inherit',
              bottom: 46,
              right: 0,
              position: 'absolute',
            }}
            isDialog={isDialog}
            title={t('broadcast:emojiTooltip')}
            isEmoji44
            iconColor={color.$10}
            onClick={onEmojiClick}
            tootipClass={classes.tootip}
          />
        </div>
      )}
    </div>
  );
};

TextEditor.defaultProps = {
  onChange: () => {},
  placeholder: '',
  origriData: '',
  maxLength: 0,
  addTooltip: '',
  isShowCustomerName: true,
  isShowEmoji: true,
  isDialog: false,
};

TextEditor.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  origriData: PropTypes.string,
  maxLength: PropTypes.number,
  addTooltip: PropTypes.string,
  isShowCustomerName: PropTypes.bool,
  isShowEmoji: PropTypes.bool,
  isDialog: PropTypes.bool,
};

export default TextEditor;
