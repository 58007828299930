import qs from 'query-string';
import moment from 'moment';
import coreAPI, { partnerTagAPI, nextAPI } from '../common/axios';
import config from '../config';
import { FollowType } from '../types';
import { transformCustomerGroupQuery } from './ServiceUtility';

let customerAccountinRequest = null;
let customerCountRequest = null;
let customersRequest = null;
let setCustomerRequest = null;

export default class CustomerService {
  /**
   *
   * @param {string} orgId - required
   * @param {object} query - required
   */
  static async getCustomerCount(orgId, query, options) {
    const realQuery = transformCustomerGroupQuery(query);

    if (customerCountRequest) {
      customerCountRequest.cancel('getCustomers canceled');
    }

    customerCountRequest = nextAPI.CancelToken.source();

    const response = await nextAPI.post(
      '/broadcast/getCustomers',
      {
        where: {
          ...realQuery,
          orgId,
        },
        options,
        returnCount: true,
      },
      {
        cancelToken: customerCountRequest.token,
      },
    );
    return response.result.count;
  }

  /**
   *
   * @param {string} objectId
   */
  static async setCustomer(objectId, data) {
    if (setCustomerRequest) {
      setCustomerRequest.cancel('setCustomer canceled');
    }

    setCustomerRequest = nextAPI.CancelToken.source();

    await coreAPI.post(
      `/classes/Customer/${objectId}`,
      {
        ...data,
        _method: 'PUT',
      },
      {
        cancelToken: setCustomerRequest.token,
      },
    );
  }

  static async getCustomer(objectId, options) {
    const response = await coreAPI.post(`/classes/Customer/${objectId}`, {
      _method: 'GET',
      ...options,
    });
    return response;
  }

  /**
   *
   * @param {string} orgId - required
   * @param {object} query - required
   * @param {number} skip - optional
   * @param {boolean} isFirst - optional. For first query
   */
  static async getCustomers(orgId, query, options, skip = 0, isFirst = false) {
    const realQuery = transformCustomerGroupQuery(query);

    if (customersRequest) {
      customersRequest.cancel('getCustomers canceled');
    }

    customersRequest = nextAPI.CancelToken.source();

    const response = await nextAPI.post(
      '/broadcast/getCustomers',
      {
        where: {
          ...realQuery,
          orgId,
        },
        select: ['joinedAt', 'lastMessageAt', 'friendship', 'conversation'],
        options,
        skip,
        limit: isFirst
          ? config.ALL_CUSTOMERS_FIRST_LIMIT_NUMBER
          : config.ALL_CUSTOMERS_LIMIT_NUMBER,
        sliceTagsCount: 12,
      },
      {
        cancelToken: customersRequest.token,
      },
    );
    return response.result.customers;
  }

  static async getCustomersByParseServer(orgId, query, options) {
    const response = await coreAPI.post('/classes/Customer', {
      _method: 'GET',
      where: {
        ...query,
        organization: {
          className: 'Organization',
          __type: 'Pointer',
          objectId: orgId,
        },
      },
      skip: 0,
      limit: undefined,
      ...options,
    });
    return response.results;
  }

  static async getCustomerAccounting(objectId, isCancel = false) {
    if (customerAccountinRequest && isCancel) {
      customerAccountinRequest.cancel();
    }

    customerAccountinRequest = coreAPI.CancelToken.source();

    const response = await coreAPI.post(
      '/classes/Customer',
      {
        limit: 1,
        where: {
          objectId,
        },
        keys:
          'tagDensity,ec,bind,extra,friendship,customerId,originalDisplayName,lastMessageAt,joinedAt,birthday,about,nation,address,location,language,gender,customField3,customField2,customField1,cellPhone,displayName,email,externalTag,picture,platform,tag',
        _method: 'GET',
      },
      {
        cancelToken: customerAccountinRequest.token,
      },
    );

    if (response.results.length > 0) {
      const data = response.results[0];
      return {
        objectId,
        customerId: data.customerId,
        joinedAt: data.joinedAt?.iso ? moment(data?.joinedAt?.iso) : '',
        conversationDate: data.lastMessageAt?.iso
          ? moment(data.lastMessageAt?.iso)
          : '',
        avatarUrl: data.picture,
        platform: data.platform?.objectId,
        displayName: data.displayName,
        friendship: data.friendship || FollowType.FOLLOW,
        originalDisplayName: data.originalDisplayName,
        cellPhone: data.cellPhone,
        email: data.email,
        birthday: data.birthday ? data.birthday?.iso : undefined,
        gender: data.gender,
        language: data.language,
        nation: data.nation,
        address: data.address,
        about: data.about,
        customField1: data.customField1,
        customField2: data.customField2,
        customField3: data.customField3,
        tagDensity: data.tagDensity || [],
        externalTag: data.externalTag || {},
        location: data.location,
        extra: data?.extra,
        bindName: data?.bindName,
        bind: data?.bind,
        ec: data?.ec,
      };
    }

    const customer = await this.getCustomer(objectId);
    return {
      objectId,
      customerId: customer?.customerId,
      joinedAt: customer?.joinedAt?.iso ? moment(customer?.joinedAt?.iso) : '',
      conversationDate: customer?.lastMessageAt?.iso
        ? moment(customer?.lastMessageAt?.iso)
        : '',
      avatarUrl: customer?.picture,
      platform: customer?.platform?.objectId,
      displayName: customer?.displayName,
      friendship: customer?.friendship || FollowType.FOLLOW,
      originalDisplayName: customer?.originalDisplayName,
      cellPhone: customer?.cellPhone,
      email: customer?.email,
      birthday: customer?.birthday?.iso,
      gender: customer?.gender,
      language: customer?.language,
      nation: customer?.nation,
      address: customer?.address,
      about: customer?.about,
      customField1: customer?.customField1,
      customField2: customer?.customField2,
      customField3: customer?.customField3,
      tagDensity: customer?.tagDensity || [],
    };
  }

  static async getPartnerTagIntegration(orgId) {
    const response = await partnerTagAPI.get(`/integration/${orgId}`);
    return response.integration;
  }

  static async setPartnerTagIntegration(orgId, data) {
    const query = {
      organization: orgId,
      ...data,
    };
    const response = await partnerTagAPI.patch(`/integration`, query);
    return response.results;
  }

  static async createPartnerTagIntegration(orgId, data) {
    const query = {
      organization: orgId,
      ...data,
    };
    const response = await partnerTagAPI.post(`/integration`, query);
    return response.results;
  }

  static isCancel(value) {
    return coreAPI.isCancel(value);
  }

  static async updateCustomFields(orgId, data) {
    const query = {
      where: { orgId },
      fields: data,
    };
    const response = await coreAPI.post(`/functions/updateCustomFields`, query);
    return response.results;
  }

  static async bindCustomer({ orgId, customerId, bindRoleId }) {
    const query = {
      organization: {
        className: 'Organization',
        objectId: orgId,
      },
      customer: {
        className: 'Customer',
        objectId: customerId,
      },
      toRole: bindRoleId,
      method: 'add',
    };
    const response = await coreAPI.post(`/functions/bind`, query);
    return response.results;
  }

  static async unbindCustomer({ orgId, customerId, provider }) {
    const response = await nextAPI.post(`/ec/${provider}/unbindCustomer`, {
      orgId,
      customerId,
    });
    return response;
  }

  static async getCustomerHistory(orgId, customerId, skip = 0, limit = 10) {
    if (!orgId) {
      throw new Error('CustomerService.getCustomerHistory: orgId undefined');
    }

    if (!customerId) {
      throw new Error(
        'CustomerService.getCustomerHistory: customerId undefined',
      );
    }

    const response = await nextAPI.get(
      `/ec/customer/history?${qs.stringify({
        orgId,
        customerId,
        skip,
        limit,
      })}`,
    );
    return response || {};
  }

  static async getCustomerConnectedPlatforms(orgId, customerId) {
    if (!orgId) {
      throw new Error(
        'CustomerService.getCustomerConnectedPlatforms: orgId undefined',
      );
    }

    if (!customerId) {
      throw new Error(
        'CustomerService.getCustomerConnectedPlatforms: customerId undefined',
      );
    }

    const response = await nextAPI.get(
      `/ec/customer/connectedPlatforms?${qs.stringify({
        orgId,
        customerId,
      })}`,
    );

    return response || [];
  }

  static async addNote(customerId, orgId, note) {
    const query = {
      orgId,
      customerId,
      note,
    };
    const response = await nextAPI.post(`/note/create`, query);
    return response.results;
  }

  static async editNote(customerId, orgId, note, noteId) {
    const query = {
      orgId,
      customerId,
      note,
    };
    const response = await nextAPI.post(`/note/update/${noteId}`, query);
    return response.results;
  }

  static async deleteNote(customerId, orgId, noteId) {
    const query = {
      orgId,
      customerId,
    };
    const response = await nextAPI.post(`/note/delete/${noteId}`, query);
    return response.results;
  }

  static async getNote(customerId, orgId) {
    const response = await nextAPI.get(
      `/note/get?${qs.stringify({
        orgId,
        customerId,
      })}`,
    );
    return response.results;
  }
}
