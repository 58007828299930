import axios from 'axios';
import {
  FacebookTagType,
  PlatformType,
  MessageContentType,
  WhatsappTagType,
} from '../types';
import coreAPI, { nextAPI } from '../common/axios';
import config from '../config';
import { simpleHash, numberMul, percentage } from '../common/utils';

export const getECWhere = (condition, ecWhere = {}) => {
  const result = {};
  if (condition.type === 'tags') {
    if ((condition?.value?.tagsName || []).length) {
      result[condition?.type] = [
        ...(ecWhere[condition?.type] || []),
        condition?.value,
      ];
    }
  } else if (
    (condition.type === 'pointBalance' || condition.type === 'creditBalance') &&
    condition?.value?.start === undefined &&
    condition?.value?.end === undefined
  ) {
    result[condition?.type] = undefined;
  } else {
    result[condition?.type] = condition?.value;
  }
  return result;
};

function queryTransformer(conditions) {
  const platformSet = new Set();
  const query = {};
  const customerObjectIds = [];
  const tagDensity = [];
  const partnerTag = [];

  let shopline;
  let shopify;
  let cyberbiz;
  let recurring;
  let inboxes = null;
  let gender = null;

  const hasBroadcastByFailCondition = conditions.reduce((pre, cond) => {
    return pre || cond.key === 'broadcast-by-fail';
  }, false);

  if (hasBroadcastByFailCondition) {
    return {
      resendTaskId: conditions?.[0]?.value,
    };
  }

  conditions.forEach(condition => {
    if (condition.key === 'platform') {
      if (Array.isArray(condition.value)) {
        condition.value.forEach(platform => {
          if (platform === 'all') {
            platformSet.add('line');
            platformSet.add('facebook');
          } else {
            platformSet.add(platform);
          }
        });
      } else if (condition.value === 'all') {
        Object.values(PlatformType)
          .filter(d => d !== PlatformType.LIVECHAT)
          .forEach(platform => platformSet.add(platform));
      } else {
        platformSet.add(condition.value);
      }
    } else if (condition.key === 'customer') {
      condition.value.forEach(customer => {
        customerObjectIds.push(customer.objectId);
      });
    } else if (condition.key === 'tag') {
      tagDensity.push(condition.value);
    } else if (condition.key === 'partner-tag') {
      partnerTag.push({
        type: condition.boolean,
        tags: (condition.value || []).map(cond => ({
          name: cond?.name,
          vendor: cond?.vendor,
        })),
      });
    } else if (condition.key === 'inbox') {
      inboxes = condition.value;
    } else if (condition.key === 'gender') {
      if (gender) {
        gender = null;
      } else {
        gender = condition.value;
      }
    } else if (condition.key === 'shopline') {
      shopline = {
        ...shopline,
        ...getECWhere(condition, shopline),
      };
    } else if (condition.key === 'shopify') {
      shopify = {
        ...shopify,
        ...getECWhere(condition, shopify),
      };
    } else if (condition.key === 'cyberbiz') {
      cyberbiz = {
        ...cyberbiz,
        ...getECWhere(condition, cyberbiz),
      };
    } else if (condition.key === 'recurring') {
      recurring = condition?.value[0];
    }
  });

  if (platformSet.size > 0) {
    query.platforms = Array.from(platformSet.values());
  }

  if (customerObjectIds.length > 0) {
    query.customerObjectIds = customerObjectIds;
  }

  query.tagDensity = tagDensity;
  query.partnerTag = partnerTag;
  query.shopline = shopline;
  query.shopify = shopify;
  query.cyberbiz = cyberbiz;
  query.recurring = recurring;

  if (inboxes) {
    query.inboxes = inboxes;
  }
  if (gender && gender.length === 1) {
    query.gender = gender;
  }

  return query;
}

export const getImagemapData = (msg, altText) => {
  const screenSize = 320;
  const { size } = msg.data;
  const scale = size.width / screenSize;
  const aspectRatio =
    size.width >= size.height
      ? `${size.width / size.height}:1`
      : `1:${size.height / size.width}`;

  return {
    name: msg.data.name,
    altText,
    className: 'Template',
    contentType: 'application/x-template',
    templateType: 'imagemap',
    index: msg.index,
    data: {
      altText,
      templateType: 'imagemap',
      elements: [
        {
          title: msg.data.name,
          imageUrl: msg.data.imageUrl,
          size,
          aspectRatio,
          messageTemplateType: msg.data.messageTemplateType,
          buttons: msg.data.actions.map(d => {
            return {
              title: d.action.displayText,
              type: d.action.type,
              data: d.action.displayText,
              tags: d.action.tags,
              x: percentage(numberMul(d.bounds.x, scale), size.width),
              y: percentage(numberMul(d.bounds.y, scale), size.height),
              width: percentage(numberMul(d.bounds.width, scale), size.width),
              height: percentage(
                numberMul(d.bounds.height, scale),
                size.height,
              ),
            };
          }),
        },
      ],
    },
  };
};

let broadcastSource = null;
let tasksSource = null;

export default class BroadcastService {
  static async sendBroadcast(
    taskId,
    orgId,
    name,
    conditions,
    fbMessengerTag,
    whatsappTag,
    scheduleAt,
    shareMessageSetting,
    quickReply,
    messages,
    altText,
    customerNum = 0,
    isDraft,
  ) {
    let templateData = {};
    const tempMessages = [];
    const templates = [];
    const hasCustomerGroupCondition = conditions.reduce((pre, cond) => {
      return pre || cond.key === 'customer-group';
    }, false);

    messages.forEach(msg => {
      let tempMsg = null;

      if (msg?.waTemplateId) {
        tempMsg = {
          waTemplateId: msg.waTemplateId,
        };
        tempMessages.push(tempMsg);
        return;
      }

      const contentType = msg?.contentType;
      const templateType = msg?.templateType || msg?.data?.templateType;
      switch (contentType || templateType) {
        case MessageContentType.TYPE_TEXT_PLAIN:
        case MessageContentType.TYPE_IMAGE:
        case MessageContentType.TYPE_AUDIO:
        case MessageContentType.TYPE_VIDEO:
        case MessageContentType.TYPE_FILE:
          tempMessages.push(msg);
          break;
        case MessageContentType.TYPE_TEMPLATE:
          if (templateType === 'card') {
            tempMsg = { ...msg };
            delete tempMsg.id;
            templateData = {
              ...tempMsg,
              altText,
              data: {
                elements: msg.data.elements.map(element => {
                  return {
                    title: element.title,
                    subtitle: element.subtitle,
                    imageType: element.imageType,
                    imageUrl: element.imageUrl,
                    aspectRatio: element.aspectRatio,
                    buttons: element.buttons.map(button => {
                      const b = { ...button };
                      delete b.id;
                      return b;
                    }),
                  };
                }),
                templateType: 'card',
              },
            };
            delete templateData.template;
            delete templateData.templateId;
            templates.push(templateData);
          } else if (templateType === 'image') {
            tempMsg = { ...msg };
            delete tempMsg.id;
            templateData = {
              ...tempMsg,
              altText,
              data: {
                elements: msg.data.elements.map(element => {
                  return {
                    imageType: element.imageType,
                    imageUrl: element.imageUrl,
                    aspectRatio: element.aspectRatio,
                    buttons: element.buttons.map(button => {
                      const b = { ...button };
                      delete b.id;
                      return b;
                    }),
                  };
                }),
                templateType: 'image',
              },
            };
            delete templateData.template;
            delete templateData.templateId;
            templates.push(templateData);
          } else if (templateType === 'confirm') {
            tempMsg = { ...msg };
            delete tempMsg.data.extension;
            delete tempMsg.data.quickReply;
            tempMsg.data.templateType = 'confirm';
            tempMsg.altText = altText;
            templates.push(tempMsg);
          } else if (templateType === 'imagemap') {
            if (hasCustomerGroupCondition) {
              conditions.push({
                key: 'platform',
                value: 'line',
              });
            }

            templates.push(getImagemapData(msg, altText));
          } else if (templateType === 'richvideo') {
            tempMsg = { ...msg };
            tempMsg.data.templateType = 'richvideo';
            tempMsg.altText = altText;
            templates.push(tempMsg);
            break;
          }
          break;
        case 'image':
          tempMsg = { ...msg };
          delete tempMsg.id;
          templateData = {
            ...tempMsg,
            altText,
            data: {
              elements: msg.data.elements.map(element => {
                return {
                  imageType: element.imageType,
                  imageUrl:
                    element.imageType === 'url'
                      ? encodeURI(element.imageUrl)
                      : element.imageUrl,
                  aspectRatio: element.aspectRatio,
                  buttons: element.buttons.map(button => {
                    const b = { ...button };
                    delete b.id;
                    return b;
                  }),
                };
              }),
              templateType: 'image',
            },
          };
          delete templateData.template;
          delete templateData.templateId;
          templates.push(templateData);
          break;
        case 'card':
          tempMsg = { ...msg };
          delete tempMsg.id;
          templateData = {
            ...tempMsg,
            altText,
            data: {
              elements: msg.data.elements.map(element => {
                return {
                  title: element.title,
                  subtitle: element.subtitle,
                  imageType: element.imageType,
                  imageUrl:
                    element.imageType === 'url'
                      ? encodeURI(element.imageUrl)
                      : element.imageUrl,
                  aspectRatio: element.aspectRatio,
                  buttons: element.buttons.map(button => {
                    const b = { ...button };
                    delete b.id;
                    return b;
                  }),
                };
              }),
              templateType: 'card',
            },
          };
          delete templateData.template;
          delete templateData.templateId;
          templates.push(templateData);
          break;
        case 'confirm':
          tempMsg = { ...msg };
          delete tempMsg.data.extension;
          delete tempMsg.data.quickReply;
          tempMsg.data.templateType = 'confirm';
          tempMsg.altText = altText;
          templates.push(tempMsg);
          break;
        case 'imagemap':
          if (hasCustomerGroupCondition) {
            conditions.push({
              key: 'platform',
              value: 'line',
            });
          }
          templates.push(getImagemapData(msg, altText));
          break;
        case 'richvideo':
          tempMsg = { ...msg };
          tempMsg.data.templateType = 'richvideo';
          tempMsg.altText = altText;
          templates.push(tempMsg);
          break;
        default:
      }
    });

    const payload = {
      name,
      orgId,
      query: { orgId, ...queryTransformer(conditions) },
      scheduleAt: scheduleAt || undefined,
      shareMessageSetting,
      quickReply,
      messages: tempMessages.map(m => ({ ...m, version: 2 })),
      templates: templates.map(t => ({ ...t, version: 2 })),
      options: {
        name,
        fbMessengerTag:
          fbMessengerTag === FacebookTagType.NO_TAG
            ? undefined
            : fbMessengerTag,
        whatsappTag:
          whatsappTag === WhatsappTagType.NO_TAG ? undefined : whatsappTag,
        applyFacebook24Policy: true,
        applyLineUnfollowFilter: true,
        customerNum,
        isDraft,
      },
    };

    if (conditions[1] && conditions[1].key === 'customer-group') {
      payload.groupId = conditions[1].value[0];
    }

    if (taskId) {
      return nextAPI.post(
        '/broadcast/update',
        { ...payload, query: { ...payload?.query, taskId } },
        { headers: { 'content-type': 'application/json' } },
      );
    }

    return nextAPI.post('/broadcast/create', payload, {
      headers: { 'content-type': 'application/json' },
    });
  }

  static async stopBroadcast(orgId, taskId) {
    if (!orgId) {
      throw new Error('BroadcastService.stopBroadcast: orgId undefined');
    }
    if (!taskId) {
      throw new Error('BroadcastService.stopBroadcast: taskId undefined');
    }
    const payload = { orgId, taskId, query: { orgId, taskId } };

    return nextAPI.post('/broadcast/pause', payload, {
      headers: { 'content-type': 'application/json' },
    });
  }

  static async getBroadcastList(
    orgId,
    options,
    whereConditions,
    isCancel = false,
  ) {
    if (!orgId) {
      throw new Error('SystemTaskService.getTaskList: orgId undefined');
    }
    let query = `orgId=${orgId}&limit=${config.MAX_TASK_LIMIT}`;
    if (options.skip) {
      query += `&skip=${options.skip}`;
    }
    if (whereConditions && whereConditions['options.name']) {
      query += `&search=${whereConditions['options.name'].$regex}`;
    }
    if (options.status) {
      query += `&status=${options.status}`;
    }

    if (tasksSource && isCancel) {
      tasksSource.cancel();
    }

    tasksSource = nextAPI.CancelToken.source();

    const response = await nextAPI.get(`/broadcast/tasks?${query}`, {
      cancelToken: tasksSource.token,
    });
    return response.results;
  }

  static async crawl(url) {
    const urlHash = simpleHash(url);
    const listRawData = localStorage.getItem('goods_url-list');

    if (!listRawData) {
      localStorage.setItem('goods_url-list', '{}');
    }

    if (listRawData) {
      const list = JSON.parse(listRawData);
      const goodsData = list[urlHash];

      // cache 7 days
      if (
        goodsData &&
        Math.floor(Date.now()) <
          Math.floor(Date.parse(goodsData.cacheDate) + 604800000)
      ) {
        return {
          title: goodsData?.title,
          subtitle: goodsData?.subtitle,
          imageUrl: goodsData?.imageUrl,
          buttons: goodsData?.buttons,
        };
      }
    }

    const response = await coreAPI.get(`${config.META_TAG_CRAWLER_URL}`, {
      params: {
        url,
      },
    });

    localStorage.setItem(
      'goods_url-list',
      JSON.stringify({
        ...JSON.parse(listRawData),
        [urlHash]: {
          title: response?.result?.title,
          subtitle: response?.result?.description,
          imageUrl: response?.result?.image?.url,
          buttons: response?.result?.buttons,
          cacheDate: new Date().toISOString(),
        },
      }),
    );

    return {
      title: response?.result?.title,
      subtitle: response?.result?.description,
      imageUrl: response?.result?.image?.url,
      buttons: response?.result?.buttons,
    };
  }

  static async getBroadcast(orgId, taskId, isEdit) {
    if (!orgId) {
      throw new Error('getBroadcast: orgId undefined');
    }
    if (!taskId) {
      throw new Error('getBroadcast: taskId undefined');
    }
    const { CancelToken } = axios;

    if (broadcastSource) {
      broadcastSource.cancel('getBroadcast canceled');
    }

    broadcastSource = CancelToken.source();

    const response = await nextAPI.get(
      `/broadcast/tasks/${taskId}${isEdit ? '?mode=edit' : ''}`,
    );
    return response.results;
  }

  static async getBroadcastOpenlog(orgId, taskId) {
    if (!orgId) {
      throw new Error('getBroadcastOpenlog: orgId undefined');
    }
    if (!taskId) {
      throw new Error('getBroadcastOpenlog: taskId undefined');
    }
    const query = {
      where: {
        orgId,
        taskId,
      },
    };

    const response = await coreAPI.post(
      '/functions/getBroadcastOpenlog',
      query,
    );
    return response.result;
  }

  static async getCustomers(
    orgId,
    query = {},
    options = {},
    axiosConfig = undefined,
  ) {
    if (!orgId) {
      throw new Error('BroadcastService.getCustomers: orgId undefined');
    }

    const response = await nextAPI.post(
      '/broadcast/getCustomers',
      {
        where: {
          ...query,
          orgId,
        },
        ...options,
        connControl: true,
        sliceTagsCount: 1,
      },
      axiosConfig,
    );

    return response;
  }

  static async tagsOperationByBroadcastAnalysis(
    orgId,
    tags,
    scope,
    option,
    to = 'task',
  ) {
    if (!orgId) {
      throw new Error('createCustomerGroupByBroadcastAnalysis orgId is empty');
    }

    if (!scope) {
      throw new Error('createCustomerGroupByBroadcastAnalysis scope is empty');
    }

    if (!tags) {
      throw new Error('createCustomerGroupByBroadcastAnalysis tags is empty');
    }

    if (!option) {
      throw new Error('createCustomerGroupByBroadcastAnalysis option is empty');
    }

    const payload = {
      method: 'add',
      tags,
      query: {
        orgId,
        [to]: {
          scope,
          ...option,
        },
      },
    };
    const response = nextAPI.post('/tag/create', payload);
    return response.result;
  }

  static async createCustomerGroupByBroadcastAnalysis(
    orgId,
    name,
    scope,
    option,
    to = 'task',
  ) {
    if (!orgId) {
      throw new Error('createCustomerGroupByBroadcastAnalysis orgId is empty');
    }

    if (!scope) {
      throw new Error('createCustomerGroupByBroadcastAnalysis scope is empty');
    }

    if (!name) {
      throw new Error('createCustomerGroupByBroadcastAnalysis name is empty');
    }

    if (!option) {
      throw new Error('createCustomerGroupByBroadcastAnalysis option is empty');
    }

    const response = await nextAPI.post(`/customer_group/create`, {
      _method: 'POST',
      name,
      query: {
        orgId,
        [to]: {
          scope,
          ...option,
        },
      },
    });

    return response.result;
  }

  static async getLineChannelInfo(integretionId) {
    if (!integretionId) {
      throw new Error(
        'BroadcastService.getLineChannelInfo: integretionId undefined',
      );
    }

    const query = {
      _method: 'GET',
    };

    const response = await coreAPI.post(
      `/classes/Integration/${integretionId}`,
      query,
    );

    return response || {};
  }

  static async exportBroadcast(orgId, startDate, endDate, i18nData) {
    if (!orgId) {
      throw new Error('BroadcastService.exportBroadcast: orgId undefined');
    }
    if (!startDate) {
      throw new Error('BroadcastService.exportBroadcast: startDate undefined');
    }
    if (!endDate) {
      throw new Error('BroadcastService.exportBroadcast: endDate undefined');
    }

    const query = {
      i18n: i18nData,
      query: {
        orgId,
        startDate,
        endDate,
      },
    };

    const response = await nextAPI.post(`/broadcast/export`, query);

    return response || {};
  }

  static async createLineLiff(orgId, liffName) {
    const response = await nextAPI.patch('/integration/line/liff', {
      orgId,
      liffName,
    });

    return response || {};
  }

  static async deleteLineLiff(orgId, liffName) {
    const response = await nextAPI.delete(`/integration/line/liff`, {
      data: { orgId, liffName },
    });

    return response || {};
  }
}
