import moment from 'moment';
import i18n from 'i18next';
import NotificationCenterService from '../services/NotificationCenterService';
import {
  NOTIFICATION_CENTER_SET_ANNOUNCEMENT,
  NOTIFICATION_CENTER_SET_ANNOUNCEMENT_FAILURE,
  NOTIFICATION_CENTER_SET_SHOW_BADGE_ICON,
  NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_CONTINUE_SUCCESS,
  NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_SUCCESS,
  NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_FAILURE,
  NOTIFICATION_CENTER_SET_ISLOADING,
  NOTIFICATION_CENTER_SET_MODAL_STATUS,
} from './type';
import { SnackBarType, ModalType, PlatformType } from '../types';
import { enqueueSnackbar } from './snackbarAction';
import { openModal, closeModal } from './modalAction';
import iconSrc from '../assets/iconSrc';

export const openNotificationCenterModal = () => dispatch => {
  dispatch({
    type: NOTIFICATION_CENTER_SET_MODAL_STATUS,
    isModalOpen: true,
  });
  dispatch(openModal(ModalType.NOTIFICATION_CENTER_MODAL));
};

export const closeNotificationCenterModal = () => (dispatch, getState) => {
  dispatch({
    type: NOTIFICATION_CENTER_SET_MODAL_STATUS,
    isModalOpen: false,
  });
  setTimeout(() => {
    const { modalType } = getState().modal;
    if (modalType === ModalType.NOTIFICATION_CENTER_MODAL) {
      dispatch(closeModal());
    }
  }, 450);
};

export const resetNotificationLastRead = () => async dispatch => {
  try {
    await NotificationCenterService.resetNotificationLastRead();
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const getAnnouncement = () => async (dispatch, getState) => {
  try {
    const { isOpen, modalType } = getState().modal;
    if (isOpen && modalType === ModalType.NOTIFICATION_CENTER_MODAL) {
      const announcements =
        (await NotificationCenterService.getAnnouncement()) || [];
      const announcement = announcements[0] || {};
      dispatch({ type: NOTIFICATION_CENTER_SET_ANNOUNCEMENT, announcement });
    }
  } catch (error) {
    dispatch({ type: NOTIFICATION_CENTER_SET_ANNOUNCEMENT_FAILURE });
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const setShowNotificationCenterBadgeIcon = isShowIcon => (
  dispatch,
  getState,
) => {
  const { isOpen, modalType } = getState().modal;
  let isOpenModal = false;
  if (isShowIcon) {
    if (isOpen && modalType === ModalType.NOTIFICATION_CENTER_MODAL) {
      isOpenModal = true;
      dispatch(resetNotificationLastRead());
    }
  }
  dispatch({
    type: NOTIFICATION_CENTER_SET_SHOW_BADGE_ICON,
    showNotificationCenterBadgeIcon: isOpenModal ? false : isShowIcon,
  });
};

export const checkNotificationLastRead = () => async dispatch => {
  try {
    const notificationLastRead = await NotificationCenterService.getNotificationLastRead();
    const needBadge = notificationLastRead?.badge > 0;
    dispatch(setShowNotificationCenterBadgeIcon(needBadge));
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

const cloneAndFix = msg => {
  const { data: msgData } = msg;
  const { body, event } = msgData || {};
  const display = body?.from?.firstName
    ? `${body?.from?.lastName ?? ''}${body?.from?.firstName ?? ''}`
    : i18n.t('notification-center:cloneAndFix.defaultDisplay');
  const msgPicture = body?.from?.picture ?? iconSrc.userDefaultImage;
  let title;
  let picture;

  switch (event) {
    case 'event/notify':
      if (body?.context?.eventType === 'inbox/assign') {
        title = i18n.t('notification-center:inbox-assign-title', {
          from: display,
          to: body?.context?.customer?.displayName ?? '',
        });
      } else if (body?.context?.eventType === 'ma/insufficient-quota') {
        title = i18n.t('ma:ma/insufficient-quota', {
          name: body?.context?.procedureName,
        });
      } else if (body?.context?.eventType === 'ma/low-quota') {
        title = i18n.t('ma:ma/low-quota', {
          name: body?.context?.procedureName,
        });
      } else if (body?.context?.eventType === 'coupon/insufficient-quota') {
        title = i18n.t('coupon:coupon/insufficient-quota', {
          name: body?.context?.couponName,
        });
      } else if (body?.context?.eventType === 'coupon/low-quota') {
        title = i18n.t('coupon:coupon/low-quota', {
          name: body?.context?.couponName,
        });
      } else if (body?.context?.eventType === 'shop8/low-quota') {
        title = i18n.t('chatPay:lowStockSystemNotify', {
          name: body?.context?.productName,
        });
      } else if (body?.context?.eventType === 'shop8/zero-quota') {
        title = i18n.t('chatPay:zeroStockSystemNotify', {
          name: body?.context?.productName,
        });
      } else if (body?.context?.eventType === 'aibot/insufficient-quota') {
        title = i18n.t('aiBot:aiBotLimit', {
          name: body?.context?.orgName,
        });
      } else if (body?.context?.eventType === 'aibot/low-quota-30') {
        title = i18n.t('aiBot:aiBotAboutLimit', {
          name: body?.context?.orgName,
        });
      } else {
        title = body?.message ?? '';
      }
      picture = iconSrc.webclip;
      break;
    case 'event/join':
      title = i18n.t('notification-center:event-join-title', {
        from: display,
        to: body?.context?.displayName ?? '',
      });
      picture = msgPicture || iconSrc.userDefaultImage;
      break;
    case 'event/left':
      title = i18n.t('notification-center:event-left-title', {
        from: display,
        to: body?.context?.displayName ?? '',
      });
      picture = msgPicture || iconSrc.userDefaultImage;
      break;
    case 'event/accept':
      title = i18n.t('notification-center:event-accept-title', {
        from: display,
        to: body?.context?.displayName ?? '',
      });
      picture = msgPicture || iconSrc.userDefaultImage;
      break;
    default:
      break;
  }

  return {
    ...msg,
    data: {
      body: {
        ...msg.data.body,
        message: {
          picture,
          title,
        },
      },
    },
  };
};

const addPlanExpireInfo = (msg, permission) => {
  const { data: msgData } = msg;
  const { body } = msgData || {};
  const bodyType = body?.type;
  const { expiredAt } = permission || {};
  let title;
  let picture;

  if (bodyType === 'plan') {
    const planType = body?.plan;
    const plan =
      planType === 'free'
        ? i18n.t('notification-center:free-type')
        : i18n.t('notification-center:other-type');

    title = i18n.t('notification-center:plan-title', {
      plan,
      expiredAt: moment((expiredAt || {}).iso).format('YYYY-MM-DD HH:mm'),
    });
    picture = iconSrc.webclip;

    return {
      ...msg,
      data: {
        body: {
          ...msg.data.body,
          message: {
            picture,
            title,
          },
        },
      },
    };
  }
  return msg;
};

const addErrorInfo = msg => {
  const { data: msgData } = msg;
  const { body } = msgData || {};
  const { type, error } = body || {};
  let title;
  let picture;

  if (type === 'error') {
    if (error?.code && error?.platform) {
      if (error?.details && error.platform === PlatformType.LINE) {
        title = i18n.t(`notification-center:line.tip.msg_error_bad_request`, {
          orgName: error?.orgName ?? '',
          errorDetail: error.details.map(d => JSON.stringify(d)).join('<br>'),
        });
      } else {
        title = i18n.t(`notification-center:${error?.platform}.${error.code}`, {
          orgName: error?.orgName ?? '',
        });
      }
    } else if (error?.platform === PlatformType.LINE) {
      title = i18n.t('notification-center:error-line-title', {
        orgName: error?.orgName ?? '',
      });
    } else if (error?.platform === PlatformType.FACEBOOK) {
      title = i18n.t('notification-center:error-fb-title', {
        orgName: error?.orgName ?? '',
      });
    } else if (error?.platform === PlatformType.WHATSAPP) {
      title = i18n.t('notification-center:error-whatsapp-title', {
        orgName: error?.orgName ?? '',
      });
    } else if (error?.platform === PlatformType.INSTAGRAM) {
      title = i18n.t('notification-center:error-ig-title', {
        orgName: error?.orgName ?? '',
      });
    }

    picture = iconSrc.webclip;

    return {
      ...msg,
      data: {
        body: {
          ...msg.data.body,
          message: {
            picture,
            title,
          },
        },
      },
    };
  }
  return msg;
};

const addLink = msg => {
  const { data: msgData } = msg;
  const { body } = msgData || {};
  const { type, link } = body || {};
  let title;
  let picture;

  if (type === 'link') {
    title = link?.title ?? '';
    picture = iconSrc.webclip;

    return {
      ...msg,
      data: {
        body: {
          ...msg.data.body,
          message: {
            picture,
            title,
          },
        },
      },
    };
  }
  return msg;
};

const doSystemMessage = (data, permission, dispatch) => {
  try {
    return data
      .map(cloneAndFix)
      .map(msg => addPlanExpireInfo(msg, permission))
      .map(addErrorInfo)
      .map(addLink);
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
    return [];
  }
};

let isLazyLoading = false;

export const getSystemMessage = (isContinue = false) => async (
  dispatch,
  getState,
) => {
  const { permission } = getState().auth;

  try {
    dispatch({
      type: NOTIFICATION_CENTER_SET_ISLOADING,
      isLoading: true,
    });

    let results = [];
    if (isContinue) {
      const dataLength = getState().notificationCenter.systemMsgs.length;
      if (isLazyLoading) {
        results = await NotificationCenterService.getSystemMessage({
          skip: dataLength,
        });
        dispatch({
          type: NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_CONTINUE_SUCCESS,
          systemMsgs: doSystemMessage(results || [], permission, dispatch),
        });
      }
    } else {
      results = await NotificationCenterService.getSystemMessage();
      isLazyLoading = results.length === 20;
      dispatch({
        type: NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_SUCCESS,
        systemMsgs: doSystemMessage(results || [], permission, dispatch),
      });
    }

    dispatch({
      type: NOTIFICATION_CENTER_SET_ISLOADING,
      isLoading: false,
    });
  } catch (error) {
    dispatch({ type: NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_FAILURE });
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const updateSystemMessage = () => async (dispatch, getState) => {
  const { permission } = getState().auth;
  try {
    const dataLength = getState().notificationCenter.systemMsgs.length;
    const results = await NotificationCenterService.getSystemMessage({
      limit: dataLength,
    });

    dispatch({
      type: NOTIFICATION_CENTER_GET_SYSTEMMSG_LIST_SUCCESS,
      systemMsgs: doSystemMessage(results || [], permission, dispatch),
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};
