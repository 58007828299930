/* eslint-disable no-param-reassign */
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import {
  buildFindMessagePayload,
  prepareAssignPayload,
  timeKey,
  transformMessageToConversationItem,
} from '../common/customerHelper';

import axiosInstance from '../common/axios';
import i18n from '../i18n';
import { MessageContentType, SnackBarType } from '../types';
import { enqueueSnackbar } from './snackbarAction';
import actionType from './type';

import config from '../config';
import CustomerService from '../services/CustomerService';
import LastReadService from '../services/LastReadService';
import MessageCenterService from '../services/MessageCenterService';

export const setData = payload => async dispatch => {
  dispatch({
    type: actionType.SET_CUSTOMER,
    payload,
  });
};

const errorHandler = err => async dispatch => {
  const errorCode =
    err.response?.data?.code ||
    err.response?.data?.error ||
    err?.message ||
    err;
  dispatch(
    enqueueSnackbar({
      variant: SnackBarType.ERROR,
      message: errorCode,
    }),
  );

  dispatch(
    setData({
      isLoading: false,
    }),
  );

  return { errorCode };
};

let lastMessageLength = 0;

// Conversation
const findMessage = (params, user) => async dispatch => {
  try {
    dispatch({
      type: actionType.FIND_CUSTOMER_REQUEST,
    });
    dispatch({
      type: actionType.SET_QUERY,
      payload: { ...params, queryType: 'message' },
    });

    const payload = buildFindMessagePayload(params);
    const { result } = await axiosInstance.post(
      '/functions/findMessageV3',
      payload,
    );
    const conversationResult = result
      .filter(customer => customer.inbox)
      .map(customer =>
        transformMessageToConversationItem(customer, user, params.orgId),
      );

    dispatch({
      type: actionType.HAS_NEXT_PAGE,
      payload: conversationResult.length === params.limit,
    });
    dispatch({
      type: actionType.FIND_CUSTOMER_SUCCESS,
      payload: conversationResult,
    });
  } catch (error) {
    dispatch({
      type: actionType.FIND_CUSTOMER_FAILURE,
    });
    dispatch(errorHandler(error));
  }
};

const getMessagelazy = (params, user) => async (dispatch, getState) => {
  try {
    const payload = buildFindMessagePayload(params);
    const { result } = await axiosInstance.post(
      '/functions/findMessageV3',
      payload,
    );
    const conversationResult = result
      .filter(customer => customer.inbox)
      .map(customer =>
        transformMessageToConversationItem(customer, user, params.orgId),
      );
    const { customerList } = getState().customer;

    dispatch({
      type: actionType.HAS_NEXT_PAGE,
      payload: conversationResult.length === params.limit,
    });
    dispatch({
      type: actionType.FETCH_CUSTOMER_SUCCESS,
      payload: [...customerList, ...conversationResult],
    });
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

const setGotoKeepMsg = value => async dispatch => {
  dispatch({
    type: actionType.SET_GOTO_KEEP_MSG,
    payload: value,
  });
};

const sortCustomerList = (tempCustomerList, params) => {
  const sortKey =
    Object.keys(params?.sort)?.[0] ||
    (params.where.inbox[0] ? timeKey(params.where.inbox[0]) : 'lastMessageAt');

  return tempCustomerList.sort((a, b) => {
    const dateA = new Date(a[sortKey].iso);
    const dateB = new Date(b[sortKey].iso);
    if (params.sort[sortKey] === -1) {
      return dateB - dateA;
    }
    return dateA - dateB;
  });
};

const setCustomerQuery = (result, params) => dispatch => {
  if (result.length > 0) {
    if (params.sort.lastMessageAt) {
      params.where.lastMessageAt = {
        iso: new Date(
          params.sort.lastMessageAt === -1
            ? Math.min(...result.map(d => new Date(d.lastMessageAt.iso)))
            : Math.max(...result.map(d => new Date(d.lastMessageAt.iso))),
        ),
        __type: 'Date',
      };
    } else if (params.sort.assignedAt) {
      params.where.assignedAt = {
        iso: new Date(
          params.sort.assignedAt === -1
            ? Math.min(...result.map(d => new Date(d.assignedAt.iso)))
            : Math.max(...result.map(d => new Date(d.assignedAt.iso))),
        ),
        __type: 'Date',
      };
    } else if (params.sort.lastInboundAt) {
      params.where.lastInboundAt = {
        iso: new Date(
          params?.sort?.lastInboundAt === -1
            ? Math.min(...result.map(d => new Date(d?.lastInboundAt?.iso)))
            : Math.max(...result.map(d => new Date(d?.lastInboundAt?.iso))),
        ),
        __type: 'Date',
      };
    }
  }

  dispatch({
    type: actionType.SET_QUERY,
    payload: { ...params, queryType: 'customer' },
  });
};

const findCustomer = (
  params,
  user,
  organization,
  isShowLoading = true,
) => async dispatch => {
  try {
    if (isShowLoading) {
      dispatch({
        type: actionType.FIND_CUSTOMER_REQUEST,
      });
    }

    const result = await MessageCenterService.getCustomersV3(
      params,
      user,
      organization,
      true,
    );

    dispatch({
      type: actionType.HAS_NEXT_PAGE,
      payload: result.length === params.limit,
    });

    dispatch({
      type: actionType.FIND_CUSTOMER_SUCCESS,
      payload: result,
    });

    dispatch(setCustomerQuery(result, params));
  } catch (error) {
    const errorMessage =
      error?.response?.data?.error || error?.message || error;

    if (
      axiosInstance.isCancel(error) ||
      errorMessage === 'getCustomersV3 canceled'
    ) {
      // eslint-disable-next-line no-console
      console.log(
        'Previous request getCustomersV3 canceled, new request is send',
      );
    } else {
      dispatch({
        type: actionType.FIND_CUSTOMER_FAILURE,
      });

      dispatch(errorHandler(error));
    }
  }
};

const getCustomerLazy = (params, user, organization) => async (
  dispatch,
  getState,
) => {
  try {
    const result = await MessageCenterService.getCustomersV3(
      params,
      user,
      organization,
    );

    const { customerList } = getState().customer;
    const tempAllCustomerIds = result.map(d => d.objectId);

    dispatch({
      type: actionType.HAS_NEXT_PAGE,
      payload: result.length === params.limit,
    });

    dispatch({
      type: actionType.FETCH_CUSTOMER_SUCCESS,
      payload: sortCustomerList(
        [
          ...customerList.filter(d => !tempAllCustomerIds.includes(d.objectId)),
          ...result,
        ],
        params,
      ),
    });

    dispatch(setCustomerQuery(result, params));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

const setCustomerList = newState => dispatch => {
  dispatch({
    type: actionType.SET_CUSTOMER_LIST,
    payload: newState,
  });
};

const assignCustomer = (
  user,
  orgId,
  inbox,
  customerObjectIds,
  member = undefined,
) => async dispatch => {
  let response = null;
  try {
    if (customerObjectIds instanceof Array) {
      const params = [];
      customerObjectIds.forEach(customerObjectId => {
        params.push({
          path: '/functions/assign',
          body: prepareAssignPayload(
            user,
            orgId,
            inbox,
            customerObjectId,
            member,
          ),
        });
      });
      response = await axiosInstance.post('/functions/batch', {
        functions: params,
      });
    } else {
      const payload = prepareAssignPayload(
        user,
        orgId,
        inbox,
        customerObjectIds,
        member,
      );
      response = await axiosInstance.post('/functions/assign', payload);
    }
    dispatch({
      type: actionType.ASSIGN_CUSTOMER,
      payload: response,
    });
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

const getCustomerByCustomerIds = (customerIds = []) => async dispatch => {
  try {
    const query = {
      where: {
        customerId: { $in: customerIds },
      },
      keys: 'customerId,displayName,picture',
      _method: 'GET',
    };

    const { results } = await axiosInstance.post('/classes/Customer', query);
    dispatch({
      type: actionType.FIND_CUSTOMER_FROM_CUSTOMERIDS,
      payload: results,
    });
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

const setSelectConvCustomer = newState => dispatch => {
  dispatch({
    type: actionType.FIND_CUSTOMER_FROM_CONVERSATION,
    payload: newState,
  });
};

const getCustomerFromId = (params, user, organization) => async dispatch => {
  try {
    const result = await MessageCenterService.getCustomersV3(
      params,
      user,
      organization,
    );

    dispatch({
      type: actionType.FIND_CUSTOMER_FROM_CONVERSATION,
      payload: result[0],
    });
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

const getCustomerAccounting = (customerId, type) => async dispatch => {
  try {
    const query = {
      where: {
        objectId: customerId,
      },
      limit: 1,
      _method: 'GET',
    };

    if (type === 'customermanage') {
      dispatch({ type: actionType.CUSTOMER_ACCOUNTING_LIST_REQUEST });

      const { results } = await axiosInstance.post('/classes/Customer', query);
      const data = results[0];
      dispatch({
        type: actionType.GET_CUSTOMER_ACCOUNTING_SUCCESS,
        data: {
          averageSessionDuration: Math.floor(data.average_session_duration / 6),
          intimacy: data.week_score,
        },
      });
    } else {
      const { results } = await axiosInstance.post('/classes/Customer', query);
      dispatch({
        type: actionType.CUSTOMER_ACCOUNTING,
        payload: results,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.CUSTOMER_ACCOUNTING_LIST_FAILURE,
      errorMessage: error.response.data.error,
    });
  }
};

const getCustomer = obj => async dispatch => {
  try {
    const query = {
      where: {
        customerId: obj.customerId,
        organization: {
          __type: 'Pointer',
          className: 'Organization',
          objectId: obj.orgId,
        },
      },
      limit: 1,
      _method: 'GET',
    };

    const { results } = await axiosInstance.post('/classes/Customer', query);
    const data = results[0];
    dispatch({
      type: actionType.GET_CUSTOMER_DETAIL_DATA_SUCCESS,
      data: {
        objectId: data.objectId,
        originalDisplayName: data.originalDisplayName,
        displayName: data.displayName,
        picture: data.picture,
        cellPhone: data.cellPhone,
        platform: data.platform.objectId,
        birthday: data.birthday ? data.birthday.iso : null,
        gender: data.gender,
        email: data.email,
        location: data.location,
        about: data.about,
        customField1: data.customField1,
        customField2: data.customField2,
        customField3: data.customField3,
      },
    });
    dispatch(getCustomerAccounting(data.objectId, obj.type));
  } catch (error) {
    dispatch({
      type: actionType.GET_CUSTOMER_DETAIL_DATA_FAILURE,
      errorMessage: error.response.data.error,
    });
  }
};

const customerAccountingList = (
  orgId,
  limit = 10,
  skip = 0,
  count = false,
  otherConditions,
) => async dispatch => {
  const query = {
    where: {
      organization: {
        className: 'Organization',
        objectId: orgId,
      },
      customer: {},
    },
    limit,
    skip,
    count,
    sort: {
      total_sent: -1,
    },
    options: {},
  };
  const realQuery = { ...query, ...otherConditions };

  let requestType = actionType.CUSTOMER_ACCOUNTING_LIST_REQUEST;
  let successType = actionType.CUSTOMER_ACCOUNTING_LIST_SUCCESS;
  let failureType = actionType.CUSTOMER_ACCOUNTING_LIST_FAILURE;

  if (otherConditions) {
    requestType =
      actionType.CUSTOMER_ACCOUNTING_LIST_WITH_CONDITION_SEARCH_REQUEST;
    successType =
      actionType.CUSTOMER_ACCOUNTING_LIST_WITH_CONDITION_SEARCH_SUCCESS;
    failureType =
      actionType.CUSTOMER_ACCOUNTING_LIST_WITH_CONDITION_SEARCH_FAILURE;
  }

  dispatch({ type: requestType });

  axiosInstance
    .post('/functions/findCustomerAccounting', realQuery)
    .then(values => {
      dispatch({
        type: successType,
        payload: values[1].result,
      });
    })
    .catch(error => {
      dispatch({ type: failureType, errorMessage: error.response.data.error });
    });
};

const getTotalCustomersNumber = (orgId, otherConditions) => async dispatch => {
  const query = {
    where: {
      organization: {
        className: 'Organization',
        objectId: orgId,
      },
    },
    count: true,
  };

  const realQuery = { ...query, ...otherConditions };

  dispatch({ type: actionType.GET_TOTAL_CUSTOMERS_NUMBER_REQUEST });

  axiosInstance
    .post('/functions/findCustomerAccounting', realQuery)
    .then(values => {
      dispatch({
        type: actionType.GET_TOTAL_CUSTOMERS_NUMBER_SUCCESS,
        payload: values[1].result.count,
      });
    });
};

const queryFirstMsgByDate = (orgId, conv, date) => async dispatch => {
  try {
    const dateFormat = moment(date).format('YYYY-MM-DD');
    const today = moment(new Date()).format('YYYY-MM-DD');
    if (today !== dateFormat) {
      const query = {
        where: {
          organization: {
            __type: 'Pointer',
            className: 'Organization',
            objectId: orgId,
          },
          conversation: {
            __type: 'Pointer',
            className: 'Conversation',
            objectId: conv,
          },
          createdAt: {
            $gte: { __type: 'Date', iso: new Date(date).toISOString() },
          },
          contentType: { $ne: MessageContentType.TYPE_NOTIFY_EVENT },
        },
        keys:
          'sendType,senderType,sender,contentType,data,createdAt,organization,conversation,error,errorOrigin,invalid,platform,lockedRoleId,fMid,errorDetails,taskId,quoteToken',
        limit: 1,
        order: 'createdAt',
        _method: 'GET',
      };

      const { results } = await axiosInstance.post('/classes/Message', query);
      dispatch({
        type: actionType.QUERY_MESSAGE_BY_DATE,
        payload: results[0],
      });
    } else {
      dispatch({
        type: actionType.QUERY_MESSAGE_BY_DATE,
        payload: dateFormat,
      });
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.ERROR,
        message: error.response?.data?.error || error.message || error,
      }),
    );
  }
};

const clearMsgDate = () => dispatch => {
  dispatch({
    type: actionType.QUERY_MESSAGE_BY_DATE,
    payload: null,
  });
};

const gotoMsgByMsg = msg => dispatch => {
  dispatch({
    type: actionType.QUERY_MESSAGE_BY_DATE,
    payload: msg,
  });
};

const removeMessageOfHistory = () => dispatch => {
  dispatch({ type: actionType.CLEAR_MESSAGE_HISTORY, payload: [] });
  dispatch({ type: actionType.CLEAR_KEPT_MESSAGE, payload: [] });
};

const receiveErrorMessage = newState => (dispatch, getState) => {
  const { messageHistory } = getState().customer;
  messageHistory.map(d => {
    if (
      (d.objectId && d.objectId === newState.objectId) ||
      (d.receiptId && d.receiptId === newState.receiptId)
    ) {
      d.invalid = true;
      d.error = newState.data.error;
      d.errorOrigin = newState.data.errorOrigin;
      d.objectId = newState.objectId;

      return d;
    }
    return d;
  });

  dispatch({
    type: actionType.QUERY_MESSAGE_HISTORY,
    payload: messageHistory,
  });
};

const setMembers = newState => async dispatch => {
  await dispatch({
    type: actionType.GET_ORGANIZATION_MEMBERS,
    payload: newState,
  });
};

const getNewMember = newState => async (dispatch, getState) => {
  const { userData } = getState().auth;
  const stateMembers = getState().organization.orgMembers;
  const stateMemberIds = stateMembers.map(d => d.objectId);

  const isMember =
    (newState.senderType === '_User' &&
      newState.sender !== userData.objectId) ||
    (newState.contentType === MessageContentType.TYPE_SHARE &&
      newState.data.senderType === '_User' &&
      newState.data.sender !== userData.objectId);

  if (isMember) {
    const tempSender =
      newState.contentType === MessageContentType.TYPE_SHARE
        ? newState.data.sender
        : newState.sender;
    const found = stateMemberIds.includes(tempSender);
    if (!found) {
      const orgResult = await MessageCenterService.getOrganizationMembers({
        memberId: tempSender,
      });

      await dispatch(setMembers([...stateMembers, ...orgResult]));
    }
  }
};

const typingSingalTimer = {};

const hideTypingSingal = payload => dispatch => {
  clearTimeout(typingSingalTimer[payload?.customerId]);

  typingSingalTimer[payload?.customerId] = setTimeout(() => {
    dispatch({
      type: actionType.HIDE_TYPING_SIGNAL,
      payload,
    });
  }, config.TYPING_TIME);
};

const showTypingSignal = payload => async dispatch => {
  dispatch({
    type: actionType.SHOW_TYPING_SIGNAL,
    payload,
  });

  dispatch(hideTypingSingal(payload));
};

const markMessageSent = newState => async (dispatch, getState) => {
  const { messageHistory } = getState().customer;
  messageHistory.map(d => {
    if (d.receiptId && d.receiptId === newState.receiptId) {
      delete d.isPending;
      d.createdAt = newState.createdAt;
      d.objectId = newState.objectId;
      return d;
    }
    return d;
  });
  dispatch({
    type: actionType.QUERY_MESSAGE_HISTORY,
    payload: messageHistory,
  });
};

const receiveMessage = newState => async (dispatch, getState) => {
  const { messageHistory, keptMessage } = getState().customer;
  let isAdd = true;
  messageHistory.map(d => {
    if (
      d.receiptId &&
      d.receiptId === (newState?.receiptId || newState?.data?.receiptId)
    ) {
      delete d.isPending;
      d.objectId = newState.objectId;
      d.createdAt = newState.createdAt;
      d.quoteToken = newState.quoteToken;
      isAdd = false;
      return d;
    }

    if (
      newState.contentType === MessageContentType.DELETE &&
      d.objectId === newState.objectId
    ) {
      d.contentType = MessageContentType.DELETE;
      return d;
    }

    if (
      (newState.contentType === MessageContentType.X_REACT ||
        newState.contentType === MessageContentType.X_UNREACT) &&
      d.objectId === newState.objectId
    ) {
      d.data.reaction = newState.data.reaction;
      isAdd = false;
      return d;
    }

    if (!newState.createdAt) {
      newState.createdAt = new Date().toISOString();
    }

    return d;
  });

  if (isAdd) {
    messageHistory.push(newState);
  }

  if (newState.contentType === MessageContentType.DELETE) {
    const findDelKeptMessage = keptMessage.find(
      d => d.message.objectId === newState.objectId,
    );
    dispatch({
      type: actionType.SET_DELETE_MESSAGE,
      payload: findDelKeptMessage,
    });
  }

  dispatch({
    type: actionType.QUERY_MESSAGE_HISTORY,
    payload: messageHistory,
  });
};

const addMessageOfConversation = newState => dispatch => {
  dispatch({
    type: actionType.ADD_MESSAGE_HISTORY,
    payload: newState,
  });
};

const getKeptMessages = messageIds => async dispatch => {
  try {
    const query = {
      where: {
        message: {
          $in: messageIds.map(d => ({
            __type: 'Pointer',
            className: 'Message',
            objectId: d,
          })),
        },
      },
      keys: 'message.objectId',
      _method: 'GET',
    };

    const { results } = await axiosInstance.post('/classes/KeptMessage', query);
    dispatch({
      type: actionType.GET_KEPT_MESSAGE,
      payload: results,
    });
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

let queryMessagesOfConversationRequest = null;

const queryMessagesOfConversation = (
  orgId,
  conversation,
  option = {},
  isShowLoading = true,
  isFirst = false,
) => async (dispatch, getState) => {
  try {
    const query = {
      where: {
        organization: {
          __type: 'Pointer',
          className: 'Organization',
          objectId: orgId,
        },
        conversation: {
          __type: 'Pointer',
          className: 'Conversation',
          objectId: conversation,
        },
        data: { $ne: null },
      },
      keys:
        'sendType,senderType,sender,contentType,data,createdAt,organization,conversation,error,errorOrigin,invalid,platform,lockedRoleId,fMid,errorDetails,taskId,quoteToken',
      limit: option.limit,
      skip: option.skip,
      order: '-createdAt',
      _method: 'GET',
    };
    if (option.before) {
      query.where.createdAt = {
        $lte: { __type: 'Date', iso: option.before },
      };
    }
    if (option.after) {
      query.where.createdAt = {
        $gt: { __type: 'Date', iso: option.after },
      };
    }
    if (option.ascending) {
      query.order = 'createdAt';
    }

    if (isShowLoading) {
      dispatch({
        type: actionType.SET_QUERY_MESSAGE_HISTORY_LOADING,
        payload: true,
      });
    }

    if (queryMessagesOfConversationRequest && isFirst) {
      queryMessagesOfConversationRequest.cancel();
    }

    queryMessagesOfConversationRequest = axiosInstance.CancelToken.source();

    const { results } = await axiosInstance.post('/classes/Message', query, {
      cancelToken: queryMessagesOfConversationRequest.token,
    });

    lastMessageLength = results.length;

    if (lastMessageLength > 0) {
      dispatch(getKeptMessages(results.map(d => d.objectId)));
    }

    const { userData } = getState().auth;
    const memberIds = uniqBy(
      results
        .filter(
          d =>
            (d.senderType === '_User' && d.sender !== userData.objectId) ||
            (d.contentType === MessageContentType.TYPE_SHARE &&
              d.data.senderType === '_User' &&
              d.data.sender !== userData.objectId),
        )
        .map(d =>
          d.contentType === MessageContentType.TYPE_SHARE
            ? d.data.sender
            : d.sender,
        ),
      d => d,
    );

    if (memberIds.length > 0) {
      const stateMembers = getState().organization.orgMembers;
      const stateMemberIds = stateMembers.map(d => d.objectId);

      const diffMemberIds = memberIds.filter(x => !stateMemberIds.includes(x));
      if (diffMemberIds.length > 0) {
        const orgResult = await MessageCenterService.getOrganizationMembers({
          memberIds: diffMemberIds,
        });

        dispatch(setMembers([...stateMembers, ...orgResult]));
      }
    }

    if (isShowLoading) {
      dispatch({
        type: actionType.SET_QUERY_MESSAGE_HISTORY_LOADING,
        payload: false,
      });
    }
    const { selectCustomer } = getState().customer;
    if (
      !results.find(
        d => d.conversation.objectId !== selectCustomer?.conversationId,
      )
    ) {
      dispatch({
        type: actionType.QUERY_MESSAGE_HISTORY,
        payload: results,
      });
    }
  } catch (error) {
    if (axiosInstance.isCancel(error)) {
      // eslint-disable-next-line no-console
      console.log(
        'Previous request queryMessagesOfConversationRequest canceled, new request is send',
      );
    } else {
      dispatch(errorHandler(error));
    }
  }
};

const queryMessagesOfConversationLazy = (
  orgId,
  conversation,
  option = {},
) => async dispatch => {
  if (lastMessageLength >= option.limit) {
    await dispatch(
      queryMessagesOfConversation(orgId, conversation, option, false),
    );
  }
};

const keepMessage = (conversationId, messageId, user) => async dispatch => {
  try {
    const payload = {
      conversation: {
        className: 'Conversation',
        objectId: conversationId,
      },
      message: {
        className: 'Message',
        objectId: messageId,
      },
      owner: {
        className: '_User',
        objectId: user,
      },
    };

    await axiosInstance.post('/functions/keep', payload);
    dispatch({
      type: actionType.GET_KEPT_MESSAGE,
      payload: [
        { objectId: `${user}${messageId}`, message: { objectId: messageId } },
      ],
    });
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

const unKeepMessage = message => async (dispatch, getState) => {
  try {
    const query = {
      _method: 'DELETE',
    };

    await axiosInstance.post(`/classes/KeptMessage/${message}`, query);

    dispatch({
      type: actionType.RELOAD_KEEP_MESSAGE,
      payload: getState().customer.keptMessage.filter(
        d => d.objectId !== message,
      ),
    });
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

const reset = () => dispatch => {
  dispatch({ type: actionType.CUSTOMER_RESET });
};

const updateCustomer = (objectId, fields, options = {}) => async dispatch => {
  const customer = {
    className: 'Customer',
    objectId,
    _method: 'put',
  };
  Object.keys(fields).forEach(key => {
    customer[key] = fields[key];
  });

  customer.birthday = {
    iso: fields.birthday,
    __type: 'Date',
  };

  if (options.type === 'customermanage') {
    if (fields.birthday === '' || !fields.birthday) {
      customer.birthday = null;
    }
    if (fields.displayName && customer.birthday === null) {
      delete customer.birthday;
    }

    dispatch({ type: actionType.UPDATE_CUSTOMER_REQUEST });
    try {
      await axiosInstance.post(`/classes/Customer/${objectId}`, customer);
      dispatch({ type: actionType.UPDATE_CUSTOMER_SUCCESS });
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.SUCCESS,
          message: i18n.t('customer-management.customer-detail.data-saved'),
        }),
      );
      dispatch(getCustomer(options));
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          variant: SnackBarType.ERROR,
          message: i18n.t(error.response.data.error),
        }),
      );
    }
  } else {
    await axiosInstance.post(`/classes/Customer/${objectId}`, customer);
    dispatch({
      type: actionType.UPDATE_CUSTOMER,
    });
  }
};

const setLastRead = newstate => async dispatch => {
  dispatch({ type: actionType.GET_LASTREAD, payload: newstate });
};

const getLastRead = (orgId, conversationId) => async (dispatch, getState) => {
  const { userData } = getState().auth;

  try {
    const lastRead = await LastReadService.getLastRead({
      orgId,
      conversationId,
      sender: userData.objectId,
      senderType: '_User',
    });
    dispatch({ type: actionType.GET_LASTREAD, payload: lastRead?.at });
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

const setAdvanceSearch = value => dispatch => {
  dispatch({ type: actionType.SET_ADVANCE_SEARCH_OPEN, payload: value });
};

const setNewFile = value => dispatch => {
  dispatch({ type: actionType.SET_NEW_FILE, payload: { value } });
};

// isContinue時，若get list result length = 0，則lazy load不在執行
let isCustomerHistoryNoOther = false;

export const getCustomerHistoryContinue = (orgId, customerId) => async (
  dispatch,
  getStore,
) => {
  try {
    if (!isCustomerHistoryNoOther) {
      const { history } = getStore()?.customer;
      const dataLength = history?.length || 0;

      const results = await CustomerService.getCustomerHistory(
        orgId,
        customerId,
        dataLength,
      );

      isCustomerHistoryNoOther = !results?.history?.length;

      dispatch(
        setData({
          history: uniqBy([...history, ...results?.history], 'id'),
          frequentPage: results?.frequentPage,
          lastConvPage: results?.lastConvPage,
        }),
      );
    }
  } catch (error) {
    isCustomerHistoryNoOther = true;
    dispatch(errorHandler(error));
  }
};

export const getCustomerHistoryInit = (orgId, customerId) => async dispatch => {
  try {
    dispatch(
      setData({
        history: [],
        frequentPage: null,
        lastConvPage: null,
      }),
    );

    isCustomerHistoryNoOther = false;

    const results = await CustomerService.getCustomerHistory(orgId, customerId);

    dispatch(setData(results));
  } catch (error) {
    dispatch(errorHandler(error));
  }
};

export const getCustomerConnectedPlatforms = (
  orgId,
  customerId,
) => async dispatch => {
  try {
    const results = await CustomerService.getCustomerConnectedPlatforms(
      orgId,
      customerId,
    );

    return results;
  } catch (error) {
    dispatch(errorHandler(error));
    return [];
  }
};

export const addNoteToCustomer = (
  customerId,
  orgId,
  note,
) => async dispatch => {
  try {
    await CustomerService.addNote(customerId, orgId, note);

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('modal.add-note-to-customer-success-notification'),
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const deleteCustomerNote = (
  customerId,
  orgId,
  noteId,
) => async dispatch => {
  try {
    await CustomerService.deleteNote(customerId, orgId, noteId);
    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('modal.delete-note-success-notification'),
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const editNoteToCustomer = (
  customerId,
  orgId,
  noteId,
  note,
) => async dispatch => {
  try {
    await CustomerService.editNote(customerId, orgId, note, noteId);

    dispatch(
      enqueueSnackbar({
        variant: SnackBarType.SUCCESS,
        message: i18n.t('modal.edit-note-to-customer-success-notification'),
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
  }
};

export const getNote = (customerId, orgId) => async dispatch => {
  try {
    const data = await CustomerService.getNote(customerId, orgId);
    return data;
  } catch (error) {
    dispatch(
      enqueueSnackbar({ variant: SnackBarType.ERROR, message: error.message }),
    );
    return null;
  }
};

export {
  addMessageOfConversation,
  assignCustomer,
  clearMsgDate,
  customerAccountingList,
  findCustomer,
  findMessage,
  getCustomer,
  getCustomerAccounting,
  getCustomerByCustomerIds,
  getCustomerFromId,
  getCustomerLazy,
  getKeptMessages,
  getLastRead,
  getMessagelazy,
  getNewMember,
  getTotalCustomersNumber,
  gotoMsgByMsg,
  keepMessage,
  markMessageSent,
  queryFirstMsgByDate,
  queryMessagesOfConversation,
  queryMessagesOfConversationLazy,
  receiveErrorMessage,
  receiveMessage,
  removeMessageOfHistory,
  reset,
  setAdvanceSearch,
  setCustomerList,
  setGotoKeepMsg,
  setLastRead,
  setMembers,
  setNewFile,
  setSelectConvCustomer,
  showTypingSignal,
  unKeepMessage,
  updateCustomer,
};
