import IconDelete20 from '../../assets/images/Icons/IconDelete20';
import IconDownload20 from '../../assets/images/Icons/IconDownload20';
import IconEdit20 from '../../assets/images/Icons/IconEdit20';
import IconEye20 from '../../assets/images/Icons/IconEye20';
import IconSend from '../../assets/images/Icons/IconSend';
import IconSave20 from '../../assets/images/Icons/IconSave20';
import IconBell from '../../assets/images/Icons/IconBell';
import IconBell20 from '../../assets/images/Icons/IconBell20';
import IconCalendar from '../../assets/images/Icons/IconCalendar';
import IconStar from '../../assets/images/Icons/IconStar';
import IconLike from '../../assets/images/Icons/IconLike';
import IconSpam from '../../assets/images/Icons/IconSpam';
import IconAssign from '../../assets/images/Icons/IconAssign';
import IconUnassign from '../../assets/images/Icons/IconUnassign';
import IconCheck from '../../assets/images/Icons/IconCheck';
import IconImage from '../../assets/images/Icons/IconImage';
import IconFile from '../../assets/images/Icons/IconFile';
import IconTemplate from '../../assets/images/Icons/IconTemplate';
import IconEmoji from '../../assets/images/Icons/IconEmoji';
import IconEmoji44 from '../../assets/images/Icons/IconEmoji44';
import IconName from '../../assets/images/Icons/IconName';
import IconSavedReplay from '../../assets/images/Icons/IconSavedReplay';
import IconFolder from '../../assets/images/Icons/IconFolder';
import IconKeepMessage from '../../assets/images/Icons/IconKeepMessage';
import IconWhatsappTemplate from '../../assets/images/Icons/IconWhatsappTemplate';

import IconX from '../../assets/images/Icons/IconX';
import IconChevronLeft from '../../assets/images/Icons/IconChevronLeft';
import IconArrowLeft from '../../assets/images/Icons/IconArrowLeft';
import IconArrowRight from '../../assets/images/Icons/IconArrowRight';
import IconCopy20 from '../../assets/images/Icons/IconCopy20';
import IconTag20 from '../../assets/images/Icons/IconTag20';
import IconShare20 from '../../assets/images/Icons/IconShare';

import IconFilter from '../../assets/images/Icons/IconFilter';
import IconFilterOpen20 from '../../assets/images/Icons/IconFilterOpen';
import IconSortUp from '../../assets/images/Icons/IconSortUp';
import IconSortDown from '../../assets/images/Icons/IconSortDown';
import IconSortChat from '../../assets/images/Icons/IconSortChat';
import IconSetting from '../../assets/images/Icons/IconSetting';

import IconLink from '../../assets/images/Icons/IconLink';

import IconAssignBack from '../../assets/images/Icons/IconAssignBack';
import IconSound from '../../assets/images/Icons/IconSound';
import IconProducts from '../../assets/images/Icons/IconProducts';
import IconReply from '../../assets/images/Icons/IconReply';

import { color } from '../../themes';

const Icon = ({
  style,
  type,
  onClick,
  className,
  id,
  mute,
  calendarOpen,
  isSaved,
  isLiked,
  width,
  height,
  infoPanel,
  iconColor,
  onlyUnstar,
  hoverColor,
}) => {
  const renderIcon = () => {
    switch (type) {
      case 'emoji':
        return (
          <IconEmoji
            id={id}
            width={width}
            height={height}
            className={className}
            onClick={onClick}
            iconColor={iconColor}
          />
        );
      case 'emoji44':
        return (
          <IconEmoji44
            id={id}
            className={className}
            onClick={onClick}
            iconColor={iconColor}
          />
        );
      case 'name':
        return (
          <IconName
            id={id}
            width={width}
            height={height}
            className={className}
            onClick={onClick}
            iconColor={iconColor}
          />
        );
      case 'icX':
        return (
          <IconX
            className={className}
            id={id}
            width={width}
            height={height}
            color={iconColor}
            hoverColor={hoverColor || iconColor}
            onClick={onClick}
          />
        );
      case 'icLeft':
        return (
          <IconChevronLeft
            className={className}
            width={width}
            height={height}
            iconColor={iconColor}
            onClick={onClick}
          />
        );
      case 'icArrowLeft':
        return (
          <IconArrowLeft
            className={className}
            width={width}
            height={height}
            onClick={onClick}
          />
        );
      case 'icArrowRight':
        return (
          <IconArrowRight
            className={className}
            width={width}
            height={height}
            onClick={onClick}
          />
        );
      case 'delete20':
        return <IconDelete20 id={id} className={className} onClick={onClick} />;
      case 'download20':
        return <IconDownload20 id={id} className={className} />;
      case 'edit20':
        return <IconEdit20 id={id} className={className} onClick={onClick} />;
      case 'send20':
        return <IconSend id={id} className={className} onClick={onClick} />;
      case 'save20':
        return <IconSave20 id={id} className={className} onClick={onClick} />;
      case 'bell':
        return (
          <IconBell
            onClick={onClick}
            mute={mute}
            iconColor={iconColor}
            width={width}
            height={height}
          />
        );
      case 'bell20':
        return (
          <IconBell20
            onClick={onClick}
            iconColor={iconColor}
            width={width}
            height={height}
          />
        );
      case 'calendar':
        return <IconCalendar onClick={onClick} open={calendarOpen} />;
      case 'star':
        return <IconStar isSaved={isSaved} onlyUnstar={onlyUnstar} />;
      case 'like':
        return <IconLike isLiked={isLiked} />;
      case 'reply':
        return (
          <IconReply
            className={className}
            id={id}
            width={width}
            height={height}
            color={iconColor}
            hoverColor={hoverColor || iconColor}
            onClick={onClick}
          />
        );
      case 'inboxLink':
        return (
          <IconLink
            id={id}
            className={className}
            width={width}
            height={height}
            onClick={onClick}
          />
        );
      case 'inboxSpam':
        return (
          <IconSpam
            id={id}
            className={className}
            width={width}
            height={height}
            onClick={onClick}
            iconColor={iconColor}
          />
        );
      case 'inboxAssign':
        return (
          <IconAssign
            id={id}
            className={className}
            width={width}
            height={height}
            onClick={onClick}
            iconColor={iconColor}
          />
        );

      case 'inboxAssignBack':
        return (
          <IconAssignBack
            id={id}
            className={className}
            width={width}
            height={height}
            onClick={onClick}
            iconColor={iconColor}
          />
        );

      case 'inboxUnassign':
        return (
          <IconUnassign
            id={id}
            className={className}
            width={width}
            height={height}
            onClick={onClick}
            iconColor={iconColor}
          />
        );
      case 'inboxCheck':
        return (
          <IconCheck
            id={id}
            className={className}
            width={width}
            height={height}
            onClick={onClick}
            iconColor={iconColor}
          />
        );
      case 'image':
        return (
          <IconImage
            id={id}
            className={className}
            onClick={onClick}
            iconColor={iconColor}
          />
        );
      case 'file':
        return (
          <IconFile
            id={id}
            iconColor={iconColor}
            hoverColor={color.$2}
            className={className}
            onClick={onClick}
          />
        );
      case 'template':
        return (
          <IconTemplate
            id={id}
            className={className}
            onClick={onClick}
            iconColor={iconColor}
          />
        );
      case 'savedReply':
        return (
          <IconSavedReplay
            id={id}
            infoPanel={infoPanel}
            className={className}
            onClick={onClick}
          />
        );
      case 'folder':
        return (
          <IconFolder
            id={id}
            infoPanel={infoPanel}
            className={className}
            onClick={onClick}
          />
        );
      case 'keepMessage':
        return (
          <IconKeepMessage
            id={id}
            infoPanel={infoPanel}
            className={className}
            onClick={onClick}
          />
        );
      case 'whatsappTemplate':
        return (
          <IconWhatsappTemplate
            id={id}
            infoPanel={infoPanel}
            className={className}
            onClick={onClick}
          />
        );
      case 'products':
        return (
          <IconProducts
            id={id}
            infoPanel={infoPanel}
            className={className}
            onClick={onClick}
          />
        );
      case 'copy':
        return (
          <IconCopy20
            id={id}
            infoPanel={infoPanel}
            className={className}
            onClick={onClick}
          />
        );
      case 'eye20':
        return <IconEye20 id={id} className={className} onClick={onClick} />;
      case 'tag20':
        return (
          <IconTag20
            iconColor={iconColor}
            hoverColor={hoverColor}
            id={id}
            className={className}
            onClick={onClick}
          />
        );
      case 'share':
        return <IconShare20 id={id} className={className} onClick={onClick} />;
      case 'IconFilter':
        return <IconFilter id={id} className={className} onClick={onClick} />;
      case 'IconFilterOpen':
        return (
          <IconFilterOpen20 id={id} className={className} onClick={onClick} />
        );
      case 'IconSortChat':
        return <IconSortChat id={id} className={className} onClick={onClick} />;
      case 'IconSortDown':
        return <IconSortDown id={id} className={className} onClick={onClick} />;
      case 'IconSortUp':
        return <IconSortUp id={id} className={className} onClick={onClick} />;
      case 'IconSetting':
        return <IconSetting iconColor={iconColor} />;

      case 'IconSound':
        return (
          <IconSound
            id={id}
            mute={mute}
            className={className}
            onClick={onClick}
          />
        );

      default:
        return null;
    }
  };
  return <span style={style}>{renderIcon()}</span>;
};

export default Icon;
